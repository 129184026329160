import Cookies from "js-cookie";

const womanDictionary = {
  mdati: "דתיה",
  mdatito: 'דת"ל תורנית',
  mmodern: "חרדי מודרני /פתוחה",
  mhardal: 'חרד"ל',
  mlight: "דתלייט",
  mtrad: "מסורתית",
  mdatil: "דתיה לאומית",
  mdatlash: "דתלשית",
  mhared: "חרדית",
  mhozer: "חוזרת בתשובה",
  mhilon: "חילונית",
  pdati: "דתי",
  pdatito: 'דת"ל תורני',
  pmodern: "חרדי מודרני /פתוח",
  phardal: 'חרד"ל',
  plight: "דתלייט",
  ptrad: "מסורתי",
  pdatil: "דתי לאומי",
  pdatlash: "דתלש",
  phared: "חרדי",
  phozer: "חוזר בתשובה",
  philon: "חילוני",
  serious: "שוקלת בכובד ראש",
  activity: "מעדיפה את השקט שלה",

  pdat1: "דתי",
  pdat2: 'דת"ל תורני',
  pdat3: "חרדי מודרני/פתוח",
  pdat4: 'חרד"ל',
  pdat5: "דתלייט",
  pdat6: "מסורתי",
  pdat7: "דתי לאומי",
  pdat8: "דתלש",
  pdat9: "חרדי",
  pdat10: "חוזר בתשובה",
  pdat11: "חילוני",

  msingle: "רווקה",
  mdivo: "גרושה",
  mdivop: " גרושה +",
  mwidow: "אלמנה",
  msingleplus: "רווקה +",

  psingle: "רווק",
  pdivo: "גרוש",
  pdivop: " גרוש +",
  pwidow: "אלמן",
  psingleplus: "רווק +",

  psta1: "רווק",
  psta2: "גרוש",
  psta3: " גרוש +",
  psta4: "אלמן",
  psta5: "רווק +",

  mstudent: "סטודנטית",
  pstudent: "סטודנט",

  msmallyeshiva: "סמינר  א-ד",
  psmallyeshiva: "ישיבה קטנה",

  mbigyeshiva: "ה-ו    / מדרשה",
  pbigyeshiva: "ישיבה גדולה",

  pedu1: "תיכונית",
  pedu2: "סטודנט",
  pedu3: "אקדמית",
  // pedu4: "אחר'",
  pedu4: "קבע, ישיבה, נדלן, 8200 וכד'",
  i_love: "אני אוהבת",
  i_appreciate: "אני מעריכה מישהו שיש בו  ",
  i_believe: " אני מאמינה ב",
  dear: "יקרה",
  connect: "תתחברי",
  come: "בואי",
  sendMessege: "שלחי הודעה וגלי בעצמך :)",
  beTheFirst: "בואי תהיי הראשונה",
  maybe: ", יתכן שהוא לא יראה שענית כן.",
  howMuchDos: "כמה את דוסית?",
  howMuchPartnerDos: "עד כמה שיהיה דוס?",
  meSuperDos: "סגורה",
  pSuperDos: "סגור",
  meSuperLight: " פתוחה ",
  pSuperLight: " פתוח",
  otherVip: "מנוי",
  enjoy_and_find_love: "תהני ותמצאי אהבה",
  someoneToYourFriend: "מישהו ואת חושבת שהוא מתאים בול לחברה שלך?",
  try: "תנסי",
  someoneCan: "מישהו שיכול",
};

const manDictionary = {
  mdati: "דתי",
  mdatito: 'דת"ל תורני',
  mmodern: "חרדי מודרני /פתוח",
  mhardal: 'חרד"ל',
  mlight: "דתלייט",
  mtrad: "מסורתי",
  mdatil: "דתי לאומי",
  mdatlash: "דתלש",
  mhared: "חרדי",
  mhozer: "חוזר בתשובה",
  mhilon: "חילוני",
  pdati: "דתיה",
  pdatito: 'דת"ל תורנית',
  pmodern: "חרדי מודרני /פתוחה",
  phardal: 'חרד"ל',
  plight: "דתלייט",
  ptrad: "מסורתית",
  pdatil: "דתיה לאומית",
  pdatlash: "דתלשית",
  phared: "חרדית",
  phozer: "חוזרת בתשובה",
  philon: "חילונית",
  serious: "שוקל בכובד ראש",
  activity: "מעדיף את השקט שלו",

  pdat1: "דתיה",
  pdat2: "דת'ל תורנית",
  pdat3: "חרדי מודרני/פתוחה",
  pdat4: 'חרד"ל',
  pdat5: "דתלייט",
  pdat6: "מסורתית",
  pdat7: "דתיה לאומית",
  pdat8: "דתלשית",
  pdat9: "חרדית",
  pdat10: "חוזרת בתשובה",
  pdat11: "חילונית",

  msingle: "רווק",
  mdivo: "גרוש",
  mdivop: "גרוש +",
  mwidow: "אלמן",
  msingleplus: "רווק +",

  psingle: "רווקה",
  pdivo: "גרושה",
  pdivop: "גרושה +",
  pwidow: "אלמנה",
  psingleplus: "רווקה +",

  psta1: "רווקה",
  psta2: "גרושה",
  psta3: "גרושה +",
  psta4: "אלמנה",
  psta5: "רווקה +",

  mstudent: "סטודנט",
  pstudent: "סטודנטית",

  msmallyeshiva: "ישיבה קטנה",
  psmallyeshiva: "סמינר     א-ד",

  mbigyeshiva: "ישיבה גדולה",
  pbigyeshiva: "ה-ו    / מדרשה",

  pedu1: "תיכונית",
  pedu2: "סטודנטית",
  pedu3: "אקדמית",
  // pedu4: "אחר",
  pedu4: "קבע, ישיבה, נדלן, 8200 וכד'",
  i_love: "אני אוהב",
  i_appreciate: "אני מעריך מישהי שיש בה  ",
  i_believe: " אני מאמין ב",
  dear: "יקר",
  connect: "תתחבר",
  come: "בוא",
  sendMessege: "שלח הודעה וגלה בעצמך :)",
  beTheFirst: "בוא תהיה הראשון",
  maybe: " :)",
  howMuchDos: "כמה אתה דוס?",
  howMuchPartnerDos: "עד כמה שתהיה דוסית?",
  meSuperDos: " סגור ",
  pSuperDos: " סגורה ",
  meSuperLight: " פתוח ",
  pSuperLight: " פתוחה ",
  otherVip: "מנויה",
  enjoy_and_find_love: "תהנה ותמצא אהבה",
  someoneToYourFriend: "מישהי ואתה חושב שהיא מתאימה בול לחבר שלך?",
  try: "תנסה",
  someoneCan: "מישהי שיכולה",
};

const hobbyDictionary = {
  1: "בישול",
  2: "ריקוד",
  3: "גלישה",
  4: "קריאה",
  5: "טיולים",
  6: "חברים",
  7: "נגינה",
  8: "ספורט",
  9: "אומנות",
  10: "בנייה",
  11: 'בע"ח',
  12: "צילום",
};

const choiceDictinoaryMan = {
  0: "טרם התקבלה תשובה",
  1: "אשמח להכיר :)",
  2: "סורי, לא פנוי",
  3: "תודה, פחות מתאים",
  4: "אין תשובה",
};

const choiceDictinoaryWoman = {
  0: "טרם התקבלה תשובה",
  1: "אשמח להכיר :)",
  2: "סורי, לא פנויה",
  3: "תודה, פחות מתאים",
  4: "אין תשובה",
};

const almostDictinoary = {
  1: "גובה",
  2: "גיל",
  3: "מרחק",
  4: "מסגרת אחרונה",
  5: "סטטוס",
  6: "התאמת חישוב AI!",
};

const regionPlaceDictinoary = {
  1: "צפון",
  2: "דרום",
  3: "מרכז",
  4: "ירושלים",
};

const stagePlaceDictinoary = {
  1: "התחלה",
  2: "דייט מתקדם",
};

const kindPlaceDictinoary = {
  1: "טבע",
  2: "אוכל",
  3: "אקסטרים",
  4: "פעילות",
};

const vipKindDictinoary = {
  0: "רכשת",
  1: "קיבלת מאיתנו מנוי מתנה בהרשמה - ",
  2: "רכשת עם נקודות",
  3: "קיבלת מאיתנו מנוי מתנה - ",
  4: "רכשת מנוי, והארכנו לך אותו:",
};

const getFamilyConnection = {
  1: "משפחה",
  2: "חבר/ה שלך",
};

export const getWordByGender = (word) => {
  // In case its a woman
  if (getGender() === "1") {
    return womanDictionary[word];
  } else {
    // In case its a man
    if (getGender() === "2") {
      return manDictionary[word];
    } else {
      // Defualt in case we missed something
      return manDictionary[word];
    }
  }
};

export const getWordOfOtherGender = (word) => {
  // In case its a woman
  if (getGender() === "1") {
    return manDictionary[word];
  } else {
    // In case its a man
    if (getGender() === "2") {
      return womanDictionary[word];
    } else {
      // Defualt in case we missed something
      return womanDictionary[word];
    }
  }
};

export const getWomanDictionary = (word) => {
  return womanDictionary[word];
};

export const getManDictionary = (word) => {
  return manDictionary[word];
};

export const getGender = () => {
  return Cookies.get("gender");
};

export const getVip = () => {
  if (Cookies.get("vip") == null) {
    return "0";
  } else {
    return Cookies.get("vip");
  }
};

export const getFirstName = () => {
  return Cookies.get("firstName");
};

export const getExpiredVip = () => {
  return Cookies.get("expiredVip");
};

export const getFriendlyTag = () => {
  return Cookies.get("friendlyTag");
};

export const getStarCount = () => {
  return Cookies.get("starCount");
};

export const getHared = () => {
  // return Cookies.get("hared");
  if (Cookies.get("hared") == "1") {
    return 1;
  } else {
    return 0;
  }
  // if (
  //   Cookies.get("userId") == "87c95a14-e5a3-11ec-92c8-36b53048f13a" ||
  //   Cookies.get("userId") == "06e66ad6-49f7-11eb-8590-36b53048f13a" ||
  //   Cookies.get("userId") == "da2d5bf2-4ee6-11eb-8f84-bd2a97778e62"
  // ) {
  //   return "1";
  // } else {
  //   return "0";
  // }
};

export const getSuperMatchMaker = () => {
  if (
    // me local
    Cookies.get("userId") === "59533a1a-86e8-11ed-b907-42972f2839a5" ||
    // me prod
    Cookies.get("userId") === "06e66ad6-49f7-11eb-8590-36b53048f13a"
  ) {
    return "1";
  } else {
    return "0";
  }
};

export const getDoubleCounsler = () => {
  if (
    // me local
    Cookies.get("userId") === "59533a1a-86e8-11ed-b907-42972f2839a5" ||
    // me prod
    Cookies.get("userId") === "06e66ad6-49f7-11eb-8590-36b53048f13a" ||
    // 4 local
    Cookies.get("userId") === "2554111e-9d5c-11ed-9c9e-368c1be015c8"
  ) {
    return "1";
  } else {
    return "0";
  }
};

export const getHobby = (word) => {
  return hobbyDictionary[word];
};

export const getOtherChoice = (word) => {
  if (getGender() === "1") {
    return choiceDictinoaryMan[word];
  } else {
    // In case its a man
    if (getGender() === "2") {
      return choiceDictinoaryWoman[word];
    } else {
      // Defualt in case we missed something
      return choiceDictinoaryWoman[word];
    }
  }
};

export const getMyChoice = (word) => {
  if (getGender() === "1") {
    return choiceDictinoaryWoman[word];
  } else {
    // In case its a man
    if (getGender() === "2") {
      return choiceDictinoaryMan[word];
    } else {
      // Defualt in case we missed something
      return choiceDictinoaryWoman[word];
    }
  }
};

export const getAlmostByVal = (word) => {
  return almostDictinoary[word];
};

export const getRegionPlace = (word) => {
  return regionPlaceDictinoary[word];
};

export const getStagePlace = (word) => {
  return stagePlaceDictinoary[word];
};

export const getKindPlace = (word) => {
  return kindPlaceDictinoary[word];
};

export const getVipKind = (word) => {
  return vipKindDictinoary[word];
};

export const getFamilyCon = (word) => {
  return getFamilyConnection[word];
};

export const getSignedIn = () => {
  if (getGender() !== "1" && getGender() !== "2") {
    return "0";
  } else {
    return "1";
  }
};

// export default getWordByGender;
