import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import "./FindPlace.css";
import HeaderBack from "../../components/Header/HeaderBack";
import axios from "axios";
import Linkify from "react-linkify";
import InfiniteScroll from "react-infinite-scroll-component";
import DiceLoading from "../../components/DiceLoading";

const shareMessageFirstPart =
  "היי מה נשמע? אני רוצה להמליץ לך על המקום הבא מהאתר double2love.com :";

const shareMessageLastPart =
  " רוצה גם שאשלח לך צילום מסך של המקום? המלצות רבות נוספות באתר דאבל השדכן הוירטואלי";

const FindPlace = () => {
  let history = useHistory();
  const [region, setRegion] = useState("0");
  const [stage, setStage] = useState("0");
  const [kind, setKind] = useState("0");
  const [isItFree, setIsItFree] = useState(false);
  const [placeList, setPlaceList] = useState([]);
  const [totalPlacesLength, setTotalPlacesLength] = useState(0);
  const [isPlacesFound, setIsPlacesFound] = useState(true);
  const [isFiltersChanged, setIsFiltersChanged] = useState(true);
  const [textToFind, setTextToFind] = useState("");

  const imgClick = (imgId) => {
    if (document.getElementById(imgId).style.width != "240px") {
      document.getElementById(imgId).style.maxHeight = "290px";
      document.getElementById(imgId).style.width = "240px";
    } else {
      document.getElementById(imgId).style.maxHeight = "100px";
      document.getElementById(imgId).style.width = "100px";
    }
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const loadMorePlaces = async (offset = 1, isReset = false) => {
    try {
      setIsFiltersChanged(false);
      const limit = 6;
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PfindMePlace",
        {
          region,
          stage,
          kind,
          isItFree: isItFree ? "1" : "0",
          textToFind,
          offset,
          limit,
        },
        config
      );

      const placesData = response.data;
      if (placesData.length > 0) {
        setTotalPlacesLength(placesData[0].total);
        if (placesData[0].total > 0) {
          setIsPlacesFound(true);
        }
      } else if (offset == 1) {
        setIsPlacesFound(false);
      } else {
        setIsPlacesFound(true);
      }

      // load iamges for places data
      const allPlacesPhotosData = await Promise.all(
        placesData.map((place) => getImage(place.idPlace))
      );

      const placesDataWithImages = placesData.map((place, index) => {
        return {
          ...place,
          imgPlace: allPlacesPhotosData[index],
        };
      });

      if (isReset) {
        setPlaceList([...placesDataWithImages]);
      } else {
        setPlaceList([...placeList, ...placesDataWithImages]);
      }
    } catch (error) {
      // probebly user is not authenticated or server is down
      console.log("wrong", error);
    }
  };

  const updatePlaceLike = async (isPlaceLiked, idPlace) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/update-like-place",
        {
          isPlaceLiked,
          idPlace,
        },
        config
      );

      const updatedPlaceList = placeList.map((place) => {
        console.log(place.idPlace, idPlace);
        if (place.idPlace === idPlace) {
          return {
            ...place,
            isLiked: isPlaceLiked,
            likeCount: isPlaceLiked ? place.likeCount + 1 : place.likeCount - 1,
          };
        } else {
          return place;
        }
      });

      console.log("updatedPlaceList");
      console.log(updatedPlaceList);

      setPlaceList(updatedPlaceList);
    } catch (error) {
      console.log("Could not update like", error);
    }
  };

  const getImage = async (serial) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/getOnePlace",
        { serial },
        config
      );
      const userData = response.data;
      if (userData[0].length > 0) {
        return userData[0][0].photo;
      }
    } catch (error) {
      // probebly user is not authenticated or server is down
      return "";
    }
  };

  return (
    <div className="aboutdouble-full-screen" id="infinity-scroll-places-form">
      <HeaderBack />
      <br />
      <form className="addplace-form">
        <h2 className="h-nomargin">לאן לצאת לדייט? </h2>

        <br />

        <div className="section-title"> אני רוצה באיזור</div>
        <div className="parent-edu">
          <input
            type="button"
            className={`region ${region === "1" ? "active" : ""}`}
            value={"צפון"}
            key="1"
            onClick={(e) => {
              setIsFiltersChanged(true);
              if (region === "1") {
                setRegion("0");
              } else {
                setRegion("1");
              }
            }}
          />
          <input
            type="button"
            className={`region ${region === "2" ? "active" : ""}`}
            value={"דרום"}
            key="2"
            onClick={(e) => {
              setIsFiltersChanged(true);
              if (region === "2") {
                setRegion("0");
              } else {
                setRegion("2");
              }
            }}
          />
          <input
            type="button"
            className={`region ${region === "3" ? "active" : ""}`}
            value={"מרכז"}
            key="3"
            onClick={(e) => {
              setIsFiltersChanged(true);
              if (region === "3") {
                setRegion("0");
              } else {
                setRegion("3");
              }
            }}
          />
          <input
            type="button"
            className={`region ${region === "4" ? "active" : ""}`}
            value={"ירושלים"}
            key="4"
            onClick={(e) => {
              setIsFiltersChanged(true);
              if (region === "4") {
                setRegion("0");
              } else {
                setRegion("4");
              }
            }}
          />
        </div>
        <br />
        <div className="section-title">השלב שלנו בקשר הוא: </div>
        <div className="parent-edu">
          <input
            type="button"
            className={`region stage ${stage === "1" ? "active" : ""}`}
            value={"התחלה "}
            key="1"
            onClick={(e) => {
              setIsFiltersChanged(true);
              if (stage === "1") {
                setStage("0");
              } else {
                setStage("1");
              }
            }}
          />
          <input
            type="button"
            className={`region stage ${stage === "2" ? "active" : ""}`}
            value={"דייט מתקדם"}
            key="2"
            onClick={(e) => {
              setIsFiltersChanged(true);
              if (stage === "2") {
                setStage("0");
              } else {
                setStage("2");
              }
            }}
          />
        </div>
        <br />
        <div className="section-title">אנחנו יותר בסגנון של: </div>
        <div className="parent-edu">
          <input
            type="button"
            className={`region ${kind === "1" ? "active" : ""}`}
            value={"טבע "}
            key="1"
            onClick={(e) => {
              setIsFiltersChanged(true);
              if (kind === "1") {
                setKind("0");
              } else {
                setKind("1");
              }
            }}
          />
          <input
            type="button"
            className={`region ${kind === "2" ? "active" : ""}`}
            value={"אוכל "}
            key="2"
            onClick={(e) => {
              setIsFiltersChanged(true);
              if (kind === "2") {
                setKind("0");
              } else {
                setKind("2");
              }
            }}
          />
          <input
            type="button"
            className={`region ${kind === "3" ? "active" : ""}`}
            value={"אקסטרים "}
            key="3"
            onClick={(e) => {
              setIsFiltersChanged(true);
              if (kind === "3") {
                setKind("0");
              } else {
                setKind("3");
              }
            }}
          />
          <input
            type="button"
            className={`region ${kind === "4" ? "active" : ""}`}
            value={"פעילות "}
            key="4"
            onClick={(e) => {
              setIsFiltersChanged(true);
              if (kind === "4") {
                setKind("0");
              } else {
                setKind("4");
              }
            }}
          />
        </div>
        <br />
        <div className="section-title">ונעדיף מקום שהוא בחינם</div>
        <br />
        <div className="toggle-line">
          <label className="toggle">
            <input
              type="checkbox"
              onChange={() => {
                setIsFiltersChanged(true);
                setIsItFree(!isItFree);
              }}
              checked={isItFree}
            />
            <span className="slider-in-toggle"></span>
          </label>

          <div className="lbl">{isItFree ? "כן, שיהיה מקום בחינם" : "לא"}</div>
        </div>
        <br />
        <div className="response-container">
          <div className="section-title" style={{ fontWeight: "bold" }}>
            אפשר גם חיפוש חופשי
          </div>

          <input
            type="text"
            placeholder="לדוגמה: שקיעה/ בר/נתניה"
            value={textToFind}
            maxLength={20}
            onChange={(e) => {
              setIsFiltersChanged(true);
              setTextToFind(e.target.value);
            }}
          ></input>
        </div>

        <div
          className="button-text-center enlarge"
          id="btnSubmit"
          onClick={() => {
            if (isFiltersChanged) {
              loadMorePlaces(1, true);
            } else {
            }
          }}
        >
          הצג מקומות
        </div>
        <br />
        {placeList.length > 0 && (
          <InfiniteScroll
            dataLength={placeList.length}
            next={() => loadMorePlaces(placeList.length + 1)}
            hasMore={placeList.length < totalPlacesLength}
            loader={
              <div className="loading-data-container">
                <div className="loading-data-div">
                  <DiceLoading />
                  <h4>טוען את המידע </h4>
                </div>
              </div>
            }
            style={{
              overflow: "unset",
            }}
            scrollableTarget="infinity-scroll-places-form"
          >
            {placeList.map((curPlace) => {
              return (
                <div
                  key={curPlace.idPlace}
                  // className="place-card"
                  style={{ height: "auto" }}
                >
                  <div
                    className={"place-card"}
                    // onClick={() => {
                    //   getImage(curPlace.idPlace);
                    // }}
                  >
                    <div className="linePlace">
                      {/* <div className="rowNum">{curPlace.rowNum}. </div> */}
                      <div className="rowTitle">{curPlace.title} </div>
                      <div className="rowTitle"> | {curPlace.waze} </div>
                    </div>
                    <div className="divider" />

                    <div>
                      <div>
                        <>
                          <img
                            className="imagePlace"
                            src={curPlace.imgPlace}
                            // id="imgPlace"
                            // onClick={(e) => imgClick("imgPlace")}
                            alt=""
                          />
                        </>
                        <div className="lbl" style={{ textAlign: "right" }}>
                          <Linkify>{curPlace.freeText} </Linkify>
                        </div>
                      </div>
                      <div className="watermark-text">
                        מומלץ ע"י Double2love.com - שדכן AI
                      </div>
                    </div>
                    <div className="divider" style={{ marginTop: "10px" }} />
                    <div className="placeButtonSection">
                      <div className="likecount">{curPlace.likeCount}</div>
                      <div
                        className={
                          curPlace.isLiked ? "likebuttonChosen" : "likebutton"
                        }
                        onClick={() =>
                          updatePlaceLike(!curPlace.isLiked, curPlace.idPlace)
                        }
                      />

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center" /* Align items vertically */,
                          justifyContent: "space-between" /* Adjust spacing */,
                          gap: "4px" /* Space between elements */,
                        }}
                      >
                        <div
                          className="whatsuppgrey"
                          // style={{ marginTop: "4px" }}
                        />
                        <a
                          href={`whatsapp://send?text=${[
                            shareMessageFirstPart,
                            "*",
                            curPlace.title,
                            "*",
                            curPlace.waze,
                            curPlace.freeText,
                            shareMessageLastPart,
                          ]
                            .join("\n")
                            .replace(/\n/gm, "%0a")}`}
                          // style={{ marginTop: "-4px" }}
                          onClick={() =>
                            localStorage.setItem("recommendPlace", 1)
                          }
                        >
                          שליחה
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        )}

        {!isPlacesFound && <div>לא נמצאו מקומות, אפשר לשנות את ההגדרות</div>}
        <br />

        <Link to={"/addplace"} style={{ textDecoration: "none" }}>
          <div
            className="prev-matches-button"
            style={{ color: "var(--btn-color)" }}
          >
            {" "}
            אהבת? רוצה להוסיף המלצה?
          </div>
        </Link>

        <br />
        <Link to={"/questionnaires"} style={{ textDecoration: "none" }}>
          <div className="button" id="backBtn">
            {" "}
            חזרה
          </div>
        </Link>
      </form>{" "}
    </div>
  );
};

export default FindPlace;
