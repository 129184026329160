import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import "../../App.css";
import "./Login.css";
import axios from "axios";
import Cookies from "js-cookie";
import { sha256 } from "js-sha256";
import eyeShowPassword from "../../images/eyeShowPassword.png";
import eyeHidePassword from "../../images/eyeHidePassword.png";

const LogIn = () => {
  let history = useHistory();

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] = useState(false);
  const [isResetErrorDisplayed, setIsResetErrorDisplayed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageInResetPassword, setErrorMessageInResetPassword] =
    useState("");
  const [passwordInputType, setPasswordInputType] = useState("password");
  const [isResetPasswordDisplayed, setIsResetPasswordDisplayed] =
    useState(false);
  const [email, setEmail] = useState("");
  const [isPasswordResetSuccessfully, setIsPasswordResetSuccessfully] =
    useState(null);

  const israelPhoneValidatorRegex =
    /^\+?(972|0)(\-)?0?(([23489]{1}\d{7})|[5]{1}\d{8})$/i;

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  useEffect(() => {
    if (Cookies.get("userId")) {
      history.push("/questionnaires");
    }
  }, []);

  var userM = 0; // i need it in global place, it calles userM - m for michal - my develop

  const alertUser = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessage(message);
  };

  const phoneValidator = (phoneNumber) => {
    return israelPhoneValidatorRegex.exec(phoneNumber) !== null;
  };

  const checkPassword = (password) => {
    if (password) {
      return true;
    } else {
      return false;
    }
  };

  const logInClicked = async () => {
    // console.log(sha256(password));
    if (phoneValidator(phone)) {
      if (checkPassword(password)) {
        // console.log("Starting log in process");
        setIsErrorMessageDisplayed(false);
        try {
          const response = await axios.post(
            process.env.REACT_APP_LOCALHOST_ADDRESS + "/PlogIn",
            {
              phone: phone,
              password: sha256(password),
            },
            config
          );

          if (Cookies.get("userId") > "0" && response.status == 200) {
            history.push("/questionnaires");
          } else {
            alertUser(
              "המשתמש לא קיים או שיש טעות בסיסמה, על מנת ליצור משתמש, אנא הירשם." +
                "   יש  מצב אולי שבחרת סיסמה עם אות גדולה והקשת אות קטנה? או להיפך?"
            );
          }
        } catch (error) {
          if (error.message.includes(500)) {
            alertUser("יש שגיאה בשרת, אנא נסה שנית עוד מעט");
          } else {
            alertUser(
              "המשתמש לא קיים או שיש טעות בסיסמה, על מנת ליצור משתמש, אנא הירשם." +
                "   יש  מצב אולי שבחרת סיסמה עם אות גדולה והקשת אות קטנה? או להיפך?"
            );
          }
        }
      } else {
        alertUser("על מנת להתחבר יש להשתמש בסיסמה");
      }
    } else {
      alertUser("מספר הטלפון לא תקין, יש להכניס מספר טלפון ישראלי");
    }
  };

  const signUpClicked = () => {
    history.push("/signup");
  };

  const resetUserPassword = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/reset/password",
        { email },
        config
      );

      // console.log(response.status);
      if (response.status === 200) {
        setIsResetErrorDisplayed(false);
        setIsPasswordResetSuccessfully(true);
        setInterval(() => {
          setIsResetPasswordDisplayed(false);
          setIsPasswordResetSuccessfully(null);
        }, 5000);
      }
    } catch (error) {
      if (error.response.status === 404) {
        setIsResetErrorDisplayed(true);
        setErrorMessageInResetPassword(
          "כתובת האימל לא קיימת במערכת, נסה שנית או צור קשר"
        );
      }
    }
  };

  const resetPasswordClicked = () => {
    // console.log("starting resetting password");
    setIsResetPasswordDisplayed(true);
  };

  return (
    <div className="login-form">
      <div className="hp-logo" />
      <form className="login-form1">
        <input
          type="number"
          id="tel"
          placeholder="טלפון"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        ></input>
        <div className="password-input-container">
          <span
            className="password-input-span"
            onClick={() => {
              if (passwordInputType === "password") {
                setPasswordInputType("text");
              } else {
                setPasswordInputType("password");
              }
            }}
          >
            {passwordInputType === "text" ? (
              <img className="eye-image-in-span" src={eyeHidePassword} />
            ) : (
              <img className="eye-image-in-span" src={eyeShowPassword} />
            )}

            {/* style ={{backgroundImage: `url(${eyeShowPassword})`}} */}
          </span>
          <input
            type={passwordInputType}
            id="pwd"
            placeholder="סיסמה"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />{" "}
        </div>
        {/* <label className="password">{label}
        <input type={this.state.type} className="password__input"/>
     
      </label> */}
        <span className="reset-password-button" onClick={resetPasswordClicked}>
          שכחתי סיסמה
        </span>
        {isErrorMessageDisplayed && (
          <div className="error-message">
            <h5> {errorMessage} </h5>{" "}
          </div>
        )}{" "}
        <div className="button-text-center" onClick={logInClicked}>
          כניסה
        </div>
        <Link
          to="/questionnaires"
          style={{
            textDecoration: "none",
          }}
        ></Link>{" "}
        {/* <Link to="/signup" style={{ textDecoration: "none" }}> */}{" "}
        <Link to="/" style={{ textDecoration: "none" }}>
          <div className="button whiteBackNBorder" id="backBtn">
            {" "}
            חזרה
            <b>→</b>
          </div>
        </Link>
        <div className="singup-line">
          <div className="section-title"> אין לך חשבון? </div>
          <div className="signup-btn" onClick={signUpClicked}>
            {" "}
            להרשמה{" "}
          </div>{" "}
        </div>
        <br />
        {/* </Link> */}
        {/* { <div className='error'>{user.logInError}</div> } */}{" "}
      </form>{" "}
      <Popup
        open={isResetPasswordDisplayed}
        contentStyle={{ minWidth: "80%", borderRadius: "5px" }}
        position="right center"
        onClose={() => setIsResetPasswordDisplayed(false)}
        closeOnDocumentClick
        closeOnEscape
      >
        <div className="reset-password-container">
          {isPasswordResetSuccessfully == true ? (
            <h4>סיסמה חדשה נשלחה למייל שלך, יתכן לתיקיית הספאם</h4>
          ) : (
            <>
              <div className="reset-password-title">איפוס סיסמה</div>
              <div className="reset-password-body">
                סיסמה חדשה תשלח לך למייל, יתכן לתיקיית הספאם
              </div>
              <input
                className="reset-password-email-input"
                type="email"
                id="email"
                value={email}
                autocomplete="off"
                placeholder='דוא"ל'
                onChange={(e) => {
                  setIsResetErrorDisplayed(false);
                  setEmail(e.target.value);
                }}
              ></input>
              <div
                className="reset-password-popup-button"
                onClick={resetUserPassword}
              >
                שלח לי סיסמה
              </div>
              {isResetErrorDisplayed ? (
                <h4>{errorMessageInResetPassword}</h4>
              ) : null}
            </>
          )}
        </div>
      </Popup>
    </div>
  );
};

export default LogIn;
