/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import "./DoubleMatch.css";
import "../../slider.css";
import HeaderBack from "./../../components/Header/HeaderBack";
import Footer from "../../components/Footer/Footer";
import GetFullMatch from "../../components/GetProfile/GetFullMatch.js";
import Popup from "reactjs-popup";
import TimerResponse from "../../components/TimerResponse/TimerResponse";

import axios from "axios";
import { getWordByGender } from "../../handlers/dictionaryHandler.js";

const DoubleMatch = () => {
  const [userName, setUserName] = useState("");
  const [curMatchNew, setCurMatchNew] = useState([]);

  const [messege, setMessege] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [isMsgDisplayed, setIsMsgDisplayed] = useState(true);

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  useEffect(async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/doubleMatchMessege",
        config
      );
      const userData = response.data;
      setMessege(userData.msg);
      localStorage.getItem("arriveFromPopup") == 1
        ? setIsMsgDisplayed(false)
        : setIsMsgDisplayed(true);
      if (localStorage.getItem("cntMatches") > "0") {
        setIsMsgDisplayed(true);
      }
      localStorage.setItem("arriveFromPopup", 0);
    } catch (error) {
      // probebly user is not authenticated or server is down
    }
  }, []);

  useEffect(async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/doubleMatchesNew",
        config
      );
      const userData = response.data.map((curMatchNew) => {
        return {
          match: curMatchNew,
          isOpen: false,
          isFullProfileOpen: false,
        };
      });
      setCurMatchNew([...userData]);
      setUserName(userData[0].match.userName);
    } catch (error) {
      // probebly user is not authenticated or server is down
    }
  }, []);

  return (
    <form className="double-match-form">
      <HeaderBack />

      <br></br>
      <div className="doublematch-body">
        <div id="h1">דאבל התאמות</div>
        <h5 style={{ textAlign: "center", marginBottom: "15px" }}>
          {userName.length > 1
            ? " הי " +
              userName +
              " " +
              getWordByGender("dear") +
              ", דאבל מצא לך התאמה! מקווים ש" +
              getWordByGender("connect") +
              " :)"
            : ""}
        </h5>

        {/* <div className="messegeMatch">{messege}</div> */}

        <Popup
          open={isMsgDisplayed}
          contentStyle={{
            maxWidth: "45%",
            borderRadius: "5px",
            direction: "rtl",
            textAlign: "center",
          }}
          position="right center"
          onClose={() =>
            // ;
            //  window.location.reload(false)
            setIsMsgDisplayed(false)
          }
          closeOnDocumentClick
          closeOnEscape
        >
          {messege}{" "}
        </Popup>

        {curMatchNew.map((pMatch) => {
          return (
            <div key={pMatch.match.MatchId} style={{ height: "auto" }}>
              <div
                className="button-text-center doubleHeight"
                style={{ fontWeight: "bold" }}
                id="btnMatch"
                onClick={() => {
                  setCurMatchNew([
                    ...curMatchNew.map((curMatchNew) => {
                      if (curMatchNew.match.MatchId === pMatch.match.MatchId) {
                        return {
                          ...curMatchNew,
                          isOpen: !curMatchNew.isOpen,
                        };
                      } else {
                        return curMatchNew;
                      }
                    }),
                  ]);
                }}
              >
                {pMatch.match.isEmailSent == 1
                  ? pMatch.match.details + ` ❤️ `
                  : pMatch.match.details}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // paddingBottom: "20px",
                    // margin: "0 auto",
                  }}
                >
                  <div style={{ marginLeft: "0px", width: "60px" }}>
                    {pMatch.match.isEmailSent == 1 ? (
                      <a
                        href={
                          `https://wa.me/972` +
                          pMatch.match.otherCellular +
                          `?text=הי+${pMatch.match.otherName}+מה+נשמע+זה+${userName}+מדאבל+:)...`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className="whatsuplink"
                          style={{ marginTop: "-15px", marginRight: "5px" }}
                        />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    style={{
                      paddingBottom: "20px",
                      marginLeft: "10px",
                      marginTop: pMatch.isOpen
                        ? pMatch.match.isEmailSent == 1
                          ? "-40px"
                          : "-20px"
                        : "0px",
                    }}
                    className={
                      !pMatch.isOpen ? "mini-triangle-down" : "mini-triangle-up"
                    }
                  ></div>
                </div>
              </div>
              {pMatch.isOpen && (
                <div id={`prevMatchReportArea` + pMatch.match.MatchId}>
                  {" "}
                  {pMatch.isOpen ? (
                    <GetFullMatch
                      matchId={pMatch.match.MatchId}
                      renewDate={pMatch.match.renewDate}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          );
        })}
        <br />
        <div
          className="linkToFindPlace shadow"
          // className="popup_lbl lbl_link shadow"
          // style={{ color: "var(--btn-color)", background: "white" }}
        >
          <Link to="/findplace" style={{ textDecoration: "none" }}>
            {"  מקום חדש לדייט הבא שלך – " +
              getWordByGender("come") +
              " לגלות!  ⭐ "}
            {/* <div class="findplaceicon" style={{ marginTop: "-4px" }} /> */}
          </Link>
        </div>

        <Link
          to="/prevmatches"
          style={{ textDecoration: "none", marginTop: "10px" }}
        >
          <div className="prev-matches-button">התאמות קודמות</div>
        </Link>
        <Link to="/Questionnaires" style={{ textDecoration: "none" }}>
          <div className="button whiteBackNBorder" id="backBtn">
            {" "}
            <b>→</b>
            חזרה
          </div>
        </Link>

        <br />
      </div>
      <Footer />
    </form>
  );
};

export default DoubleMatch;
