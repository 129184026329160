import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import "../../App.css";
import "../../slider.css";
import ReactPlayer from "react-player";
import Popup from "reactjs-popup";

import axios from "axios";
import {
  getWordByGender,
  getHobby,
  getOtherChoice,
  getHared,
  getVip,
  getAlmostByVal,
  getGender,
} from "../../handlers/dictionaryHandler.js";
import FileManager from "../../utils/FileManager.js";
import DateCount from "../Header/DateCount.js";
import DiceLoading from "../DiceLoading.js";

const cameraBtnClick = () => {
  if (document.getElementById("image1").style.display === "block") {
    document.getElementById("image1").style.display = "none";
  } else {
    document.getElementById("image1").style.display = "block";
    document.getElementById("facebook1").style.display = "none";
    document.getElementById("record1").style.display = "none";
    document.getElementById("instegram1").style.display = "none";
    document.getElementById("linkedin1").style.display = "none";
  }
};

const facebookBtnClick = () => {
  if (document.getElementById("facebook1").style.display === "block") {
    document.getElementById("facebook1").style.display = "none";
  } else {
    document.getElementById("facebook1").style.display = "block";
    document.getElementById("image1").style.display = "none";
    document.getElementById("record1").style.display = "none";
    document.getElementById("instegram1").style.display = "none";
    document.getElementById("linkedin1").style.display = "none";
  }
};

const instegramBtnClick = () => {
  if (document.getElementById("instegram1").style.display === "block") {
    document.getElementById("instegram1").style.display = "none";
  } else {
    document.getElementById("instegram1").style.display = "block";
    document.getElementById("facebook1").style.display = "none";
    document.getElementById("image1").style.display = "none";
    document.getElementById("record1").style.display = "none";
    document.getElementById("linkedin1").style.display = "none";
  }
};

const linkedinBtnClick = () => {
  if (document.getElementById("linkedin1").style.display === "block") {
    document.getElementById("linkedin1").style.display = "none";
  } else {
    document.getElementById("linkedin1").style.display = "block";
    document.getElementById("facebook1").style.display = "none";
    document.getElementById("image1").style.display = "none";
    document.getElementById("record1").style.display = "none";
    document.getElementById("instegram1").style.display = "none";
  }
};

const recordBtnClick = () => {
  if (document.getElementById("record1").style.display === "block") {
    document.getElementById("record1").style.display = "none";
  } else {
    document.getElementById("record1").style.display = "block";
    document.getElementById("image1").style.display = "none";
    document.getElementById("facebook1").style.display = "none";
    document.getElementById("instegram1").style.display = "none";
    document.getElementById("linkedin1").style.display = "none";
  }
};

const GetFullMatch = ({ matchId, renewDate }) => {
  const [userName, setUserName] = useState("");
  const [startTimer1, setStartTimer1] = useState(false);
  const [vipExplain, setVipExplain] = useState(false);

  const [choice1, setChoice1] = useState("");
  const [choice1Saved, setChoice1Saved] = useState(false);

  const [messege, setMessege] = useState("");
  const [details1, setDetails1] = useState("");
  const [otherVip1, setOtherVip1] = useState("");
  const [otherName1, setOtherName1] = useState("");
  const [freeText1, setfreeText1] = useState("");
  const [cellFriend1, setCellFriend1] = useState("");
  const [image1a, setImage1a] = useState("");
  const [image2a, setImage2a] = useState("");
  const [image3a, setImage3a] = useState("");
  const [image4a, setImage4a] = useState("");
  const [image5a, setImage5a] = useState("");
  const [image1aBlobURL, setImage1aBlobURL] = useState("");
  const [image2aBlobURL, setImage2aBlobURL] = useState("");
  const [image3aBlobURL, setImage3aBlobURL] = useState("");
  const [image4aBlobURL, setImage4aBlobURL] = useState("");
  const [image5aBlobURL, setImage5aBlobURL] = useState("");
  const [facebook1, setFacebook1] = useState("");
  const [instegram1, setInstegram1] = useState("");
  const [linkedin1, setLinkedin1] = useState("");
  const [cellular1, setCellular1] = useState("");
  const [religous1, setReligous1] = useState("");
  const [tall1, setTall1] = useState("");
  const [education1, setEducation1] = useState("");
  const [status1, setStatus1] = useState("");
  const [hobbies1, setHobbies1] = useState("");
  const [hobbieFree1, setHobbieFree1] = useState("");
  const [occupation1, setOccupation1] = useState("");
  const [origin1, setOrigin1] = useState("");
  const [otherResponse1, setOtherResponse1] = useState("");
  const [isEmailSent1, setIsEmailSent1] = useState("");
  const [record1a, setRecord1a] = useState("");
  const [myRecord1a, setMyRecord1a] = useState("");
  const [dateRecord1a, setDateRecord1a] = useState("");
  const [isRecord1aPlaying, setIsRecord1aPlaying] = useState(false);
  const [recordingBlobURL1a, setRecordingBlobURL1a] = useState("");
  const [record1b, setRecord1b] = useState("");
  const [myRecord1b, setMyRecord1b] = useState("");
  const [dateRecord1b, setDateRecord1b] = useState("");
  const [otherHadSee1, setOtherHadSee1] = useState("");
  const [msgForOtherSide1, setMsgForOtherSide1] = useState("");
  const [msgForOtherSideLength1, setMsgForOtherSideLength1] = useState("0");
  const [freeTextAreaVisible, setFreeTextAreaVisible] = useState(false);
  const [msgISent1, setMsgISent1] = useState("");
  const [msgIGot1, setMsgIGot1] = useState("");
  const [firstName1, setFirstName1] = useState("");
  const [almost1, setAlmost1] = useState("");
  const [vipMatch1, setVipMatch1] = useState("");
  const handleText1 = (text) => {
    setMsgForOtherSide1(text);
    setMsgForOtherSideLength1(text.length);
  };
  const [sendReminderDate, setSendReminderDate] = useState("");
  const [doubleYes1, setDoubleYes1] = useState("");
  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [errorMessageFirstChoice, setErrorMessageFirstChoice] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isMsgDisplayed, setIsMsgDisplayed] = useState(false);
  const [sendReminderOpen, setSendReminderOpen] = useState(false);

  let history = useHistory();
  const location = useLocation();

  const handleChoice1 = (val) => {
    if (isEmailSent1 != "1" && vipMatch1 == "1") {
      setChoice1(val);
      setChoice1Saved(true);
      // onSubmit(matchId, val);
    } else if (vipMatch1 == "0") {
      alertUserFirstChoice("ניתן לסמן תשובה כאשר אחד משני הצדדים מנוי");
    }
  };

  const alertUser = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessage(message);
  };

  const imgClick = (imgId) => {
    if (!location.pathname.includes("image")) {
      history.push("/doublematch/image" + imgId);
    } else {
      history.push("/doublematch");
    }
  };

  const alertUser2 = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessage2(message);
  };

  const alertUserFirstChoice = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessageFirstChoice(message);
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  // const onCollectStars = async (kind) => {
  //   try {
  //     const response = await axios.post(
  //       process.env.REACT_APP_LOCALHOST_ADDRESS + "/PcollectStars",
  //       {
  //         kind,
  //       },
  //       config
  //     );
  //     if (response.data === true) {
  //       alertUser2(
  //         "נשמר בהצלחה, אספת 10 נקודות!! עם נקודת ניתן לקבל מנוי במתנה!"
  //       );
  //     } else {
  //       alertUser2("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     alertUser2("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
  //   }
  // };

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_LOCALHOST_ADDRESS + "/getFullMatch",
          { idMatch: matchId },
          config
        );
        const userData = response.data;

        if (userData[0].length > 0) {
          setMsgForOtherSide1(userData[0][0].msgISent);
          if (userData[0][0].msgISent != "") {
            setFreeTextAreaVisible(true);
          }
          setUserName(userData[0][0].userName);
          setDetails1(userData[0][0].details);
          setfreeText1(userData[0][0].freeText);
          if (userData[0][0].keyImage1 !== "") {
            const image1Data = await FileManager.getFile(
              userData[0][0].keyImage1
            );
            setImage1a(image1Data.file);
            setImage1aBlobURL(image1Data.blobURL);
          }
          setReligous1(userData[0][0].religous);
          setTall1(userData[0][0].tall);
          setEducation1(userData[0][0].education);
          setStatus1(userData[0][0].status);
          setOccupation1(userData[0][0].occupation);
          setOrigin1(userData[0][0].origin);
          setHobbies1(userData[0][0].hobbies);
          setHobbieFree1(userData[0][0].hobbieFree);
          if (userData[0][0].vip == 0) {
            setVipMatch1("0");
            setIsDataLoaded(true);
          } else {
            setVipMatch1("1");
            setOtherVip1(userData[0][0].otherVip);
            setOtherName1(userData[0][0].otherName);
            setCellFriend1(userData[0][0].cellFriend);

            if (userData[0][0].keyImage2 !== "") {
              const image2Data = await FileManager.getFile(
                userData[0][0].keyImage2
              );
              setImage2a(image2Data.file);
              setImage2aBlobURL(image2Data.blobURL);
            }
            if (userData[0][0].keyImage3 !== "") {
              const image3Data = await FileManager.getFile(
                userData[0][0].keyImage3
              );
              setImage3a(image3Data.file);
              setImage3aBlobURL(image3Data.blobURL);
            }
            if (userData[0][0].keyImage4 !== "") {
              const image4Data = await FileManager.getFile(
                userData[0][0].keyImage4
              );
              setImage4a(image4Data.file);
              setImage4aBlobURL(image4Data.blobURL);
            }
            if (userData[0][0].keyImage5 !== "") {
              const image5Data = await FileManager.getFile(
                userData[0][0].keyImage5
              );
              setImage5a(image5Data.file);
              setImage5aBlobURL(image5Data.blobURL);
            }

            setFacebook1(userData[0][0].facebook);
            setInstegram1(userData[0][0].instegram);
            setLinkedin1(userData[0][0].linkedin);
            setCellular1(userData[0][0].cellular);
            setChoice1(userData[0][0].myResponse);
            setChoice1Saved(userData[0][0].myResponse > 0 ? true : false);
            setOtherResponse1(userData[0][0].otherResponse);
            setIsEmailSent1(userData[0][0].isEmailSent);
            setOtherHadSee1(userData[0][0].otherHadSee);
            setFirstName1(userData[0][0].firstName);
            setMsgISent1(userData[0][0].msgISent);
            setMsgIGot1(userData[0][0].msgIGot);
            setAlmost1(userData[0][0].almost);
            setSendReminderDate(userData[0][0].sendReminderDate);

            if (userData[0][0].rec1 !== "") {
              const rec1aBuffer = userData[0][0].rec1
                .split(",,")
                .map(
                  (current) =>
                    new Int8Array(current.split(",").map((current) => +current))
                );
              const blob1a = new Blob(rec1aBuffer, {
                type: "audio/mp3",
              });
              const blob1aURL = URL.createObjectURL(blob1a);

              setRecordingBlobURL1a(blob1aURL);
            }

            setMyRecord1a(userData[0][0].myRec1);
            setIsDataLoaded(true);
          }
        } else {
          setChoice1("0");
          setIsDataLoaded(true); // in case there is no data
        }
      } catch (error) {
        // probebly user is not authenticated or server is down
        console.log(error);
      }
    };
    loadData();
  }, []);

  const onSubmit = async (idMatch, choice, msgForOtherSide) => {
    // if ((choice1 != "" && matchId > 0) || matchId === "0") {
    if (choice !== "" && choice > 0) {
      try {
        const response = await axios.post(
          process.env.REACT_APP_LOCALHOST_ADDRESS + "/matchesResponse",
          {
            idMatch,
            choice,
            msgForOtherSide,
          },
          config
        );
        if (response.data === true) {
          alertUser("נשמר בהצלחה");
          setIsMsgDisplayed(true);
          // window.location.reload(false);
        } else {
          alertUser("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
        }
      } catch (error) {
        alertUser("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
      }
    } else {
      alertUser("עליך לסמן את הבחירה שלך");
    }
  };

  const sendReminder = async () => {
    //לשלוח מייל + סמס + לעדכן בדטהבייס
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/sendReminder",
        {
          idMatch: matchId,
        },
        config
      );
      if (response.data === true) {
        alertUser2("ההודעות נשלחו");
        setSendReminderOpen(false);
        setSendReminderDate("עכשיו :)");
      } else {
        alertUser2("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
      }
    } catch (error) {
      alertUser2("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
    }
  };

  console.log(
    "sendReminderDate",
    sendReminderDate,
    sendReminderDate.length > 0
  );
  return (
    <div className="">
      {isDataLoaded === true ? (
        <>
          <div className="" style={{ marginTop: "16px" }}>
            {getHared() == "1" && cellular1 != "" ? (
              <div className="messegeMatch">
                רוצה ששדכן מאיתנו יצור קשר עם ההורים ויציע את ההצעה הזו? אפשר
                אצל: <br />
                <div className="cellularline">
                  חיים שלר
                  <a
                    href={"https://wa.me/972549762276"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="whatsuplink" />
                  </a>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* <div className="messegeMatch">{messege}</div> */}

            <Popup
              open={isMsgDisplayed}
              contentStyle={{
                maxWidth: "45%",
                borderRadius: "5px",
                direction: "rtl",
                textAlign: "center",
              }}
              position="right center"
              onClose={() =>
                // ;
                //  window.location.reload(false)
                setIsMsgDisplayed(false)
              }
              closeOnDocumentClick
              closeOnEscape
            >
              {choice1 == 3
                ? " נהדר שהגבת! עדיף לקבל תגובה גם אם היא שלילית.⭐ מרגישים שההצעות לא מתאימות? שווה לבדוק מה סימנתם שאתם מחפשים"
                : choice1 == 2
                ? " נהדר שהגבת! אנשים שמחים לקבל כל תגובה! ⭐ שימו לב, אם הצד השני לא יגיד לא, יתכן וההצעה תופיע שוב בעוד מספר שבועות"
                : choice1 == 1 && otherResponse1 == 1
                ? "יש! דייט! שניכם עניתם שתשמחו להכיר 😀"
                : choice1 == 1 && (otherHadSee1 != "1" || otherResponse1 == "0")
                ? // ? "יהה! אולי זה יהיה המזל שלך!  💖 אם הצד השני לא יראה בקרוב את ההתאמה אתם יכולים לבקש מאיתנו לשלוח תזכורת בווטסאפ"
                  "יהה! אולי זה יהיה המזל שלך!  💖 אם הצד השני לא יראה בקרוב את ההתאמה אתם יכולים ללשלוח לו פעם אחת תזכורת בכפתור המתאים בהתאמה"
                : messege}{" "}
              <div
                className={
                  choice1 == 1 && otherResponse1 == 1 ? "lbl" : "invisible"
                }
              >
                {" "}
                <br />
                <div style={{ fontSize: "15px" }}>
                  <div>הנה לינק ישיר לווטאספ של</div>
                  {cellular1 != "" ? firstName1 : ""}{" "}
                  <div className="whatsuplinka">
                    {cellular1 != "" ? (
                      <a
                        href={
                          `https://wa.me/972` +
                          cellular1.replace("-", "") +
                          `?text=הי+${firstName1}+מה+נשמע+זה+${userName}+מדאבל+:)...`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="whatsuplink" />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* <div
                  className=""
                  // style={{ fontSize: "15px" }}
                >
                  <br />
                  רוצה לזכות בנקודות? זה פשוט, {getWordByGender(
                    "beTheFirst"
                  )}{" "}
                  לשלוח הודעה לצד השני, יש קישור לווטסאפ בפנים.
                  <br />
                  <br />
                  <div className="faceline">
                    <div className="toggle-line">
                      <label className="toggle">
                        <input
                          type="checkbox"
                          onChange={() => {
                            setIllswndwhatsupp(!illswndwhatsupp);
                          }}
                          checked={illswndwhatsupp}
                        />
                        <span className="slider-in-toggle"></span>
                      </label>

                      <div className="lbl">
                        {illswndwhatsupp ? "סבבה, עכשיו אשלח ווטסאפ" : " "}
                      </div>
                    </div>
                    <br />
                    <div
                      className={
                        illswndwhatsupp === true
                          ? "button-text-center narrow"
                          : "invisible"
                      }
                      onClick={() => {
                        onCollectStars("6");
                      }}
                    >
                      שמירה
                    </div>
                  </div>
                  {isErrorMessageDisplayed && (
                    <div className="error-message">
                      <h5>{errorMessage2} </h5>
                    </div>
                  )}
                </div> */}
              </div>
            </Popup>

            {/* <div className="infoicon" onClick={infoBtnClick}></div> */}
            {renewDate != "" ? (
              <div className="messegeMatch">
                {" "}
                ** הפעם הצעה חוזרת, כדאי לסמן תשובה :){" "}
              </div>
            ) : (
              ""
            )}
            {almost1 != "" && almost1 != "0" && almost1 != "6" ? (
              <div className="messegeMatch">
                {" "}
                ** קיבלת הצעה קרובה מאוד למה שהגדרת, למעט ה{" "}
                {getAlmostByVal(almost1)}.
                <br />
                אולי זה המזל שלך? 😊
                <br />
                אפשר להגדיר
                <Link
                  to="/Almost"
                  // style={{ textDecoration: "none" }}
                >
                  {" כאן "}
                </Link>
                מה יכול להיות ”כמעט מתאים“
              </div>
            ) : almost1 == "6" ? (
              <h5
                style={{
                  textAlign: "center",
                  marginTop: "9px",
                  marginBottom: "0px",
                }}
              >
                {" "}
                הי! הפעם זו {getAlmostByVal(almost1)}.
                <br />
                אולי זה יהיה המזל שלך? הכל יכול להיות!
                <br />
              </h5>
            ) : (
              <></>
            )}

            <div className="oneFullMatch">
              <div>
                {" "}
                {/* {startTimer1 && choice1 == "" ? (
                    <TimerResponse timerDisplay={true} maxRange={60} />
                  ) : (
                    <></>
                  )} */}
                <h5 className="vipDescription">
                  {getVip() === "1" && otherVip1 == "1"
                    ? " שניכם מנויים לאתר וניתן לראות פרטים מלאים :)"
                    : getVip() === "1"
                    ? " יש לך מנוי לאתר ולכן ניתן לראות פרטים מלאים!"
                    : otherVip1 == "1"
                    ? otherName1 +
                      " " +
                      getWordByGender("otherVip") +
                      " לאתר וניתן לראות פרטים מלאים!"
                    : ""}
                </h5>
                <div className="cellularblock">
                  <div className="messegeMatch">
                    {cellular1 != ""
                      ? "✅ שניכם סימנתם שתשמחו להכיר! "
                      : " כאן מוצג הטלפון אם שניכם מסמנים שמתאים"}
                  </div>
                  <div className="cellularline">
                    <div
                      className={cellular1 != "" ? " " : "cellularicon"}
                    ></div>

                    <div className="messegeMatch" style={{ marginTop: "8px" }}>
                      {/* <div onclick={window.open(`tel:${cellular1}`)}>
                        {" "}
                        {cellular1}{" "}
                      </div> */}
                      {cellular1 != ""
                        ? cellular1 +
                          "  בהצלחה :)  " +
                          "\n" +
                          userName +
                          "  אולי זה הדייט הבא שלך? " +
                          getWordByGender("sendMessege")
                        : // + getWordByGender("maybe")

                          ""}
                    </div>
                    <div className="whatsuplinka">
                      {cellular1 != "" ? (
                        <a
                          href={
                            "https://wa.me/972" + cellular1.replace("-", "")
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="whatsuplink" />
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className={cellular1 != "" ? "lbl" : "invisible"}>
                    <Link to="/findplace">
                      {" 📍 " +
                        getWordByGender("come") +
                        " לגלות מקום חדש לדייט הבא שלך!"}
                    </Link>
                  </div>
                </div>
                <div className="faceline">
                  {image1a !== "" ? (
                    <>
                      {location.pathname.includes("/image1a") ? (
                        <img
                          className="image-fullscreen"
                          src={image1aBlobURL}
                          alt=""
                          id="img1a"
                        />
                      ) : (
                        <img
                          className="image-doubleMatch"
                          style={{
                            border: "white 3px solid",
                            marginLeft: "6px",
                          }}
                          src={image1aBlobURL}
                          alt=""
                          id="img1a"
                        />
                      )}
                    </>
                  ) : null}
                  <div>
                    {vipMatch1 == "1" ? (
                      <div>
                        <div className="messegeMatch">
                          {otherHadSee1 == "1" && otherResponse1 == ""
                            ? "( הצד השני ראה את ההצעה וטרם הגיב)"
                            : otherHadSee1 == "1"
                            ? "(הצד השני ראה את ההצעה)"
                            : "(הצד השני טרם ראה את ההצעה)"}
                        </div>
                        <div className="messegeMatch">
                          {" "}
                          <b>תשובת הצד השני</b>
                          {": "}
                          {choice1 != "" && choice1Saved
                            ? getOtherChoice(otherResponse1)
                            : "תוצג לאחר בחירתך"}
                        </div>
                        <div
                          className="sendReminder shadow"
                          onClick={() => setSendReminderOpen(!sendReminderOpen)}
                        >
                          לשלוח תזכורת לצד השני
                        </div>

                        {sendReminderOpen && (
                          <div>
                            {otherResponse1 > 0 ? (
                              "הצד השני כבר השיב תשובה... "
                            ) : (
                              <div>
                                <div
                                  className={
                                    sendReminderDate.length > 0
                                      ? "lbl"
                                      : "invisible"
                                  }
                                >
                                  כבר נשלחה תזכורת להתאמה זו בתאריך{" "}
                                  {sendReminderDate}
                                </div>
                                <div
                                  className={
                                    sendReminderDate.length > 0
                                      ? "invisible"
                                      : "lbl"
                                  }
                                >
                                  <div>
                                    <div style={{ color: "red" }}>
                                      ניתן להשתמש בכפתור זה פעם אחת בלבד לכל
                                      התאמה.
                                    </div>
                                    האם ברצונך להשתמש עכשיו? בלחיצה על "כן",
                                    המערכת תשלח סמס ומייל להתאמה שלך ויהיה כתוב
                                    שם שהצד השני ממתין לתשובה.
                                    <br />
                                    האם לשלוח עכשיו?
                                  </div>
                                  <div
                                    className="sendReminder shadow"
                                    onClick={() => sendReminder()}
                                  >
                                    כן, לשלוח עכשיו
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                        {isErrorMessageDisplayed && (
                          <div className="error-message">
                            <h5>{errorMessage2} </h5>
                          </div>
                        )}
                      </div>
                    ) : null}
                    <div className="whiteLine" />
                    <h5>מי אני</h5>
                    <div className="messegeMatch">
                      {getWordByGender("pdat" + religous1)} , {tall1} ס'מ ,{" "}
                      {getWordByGender("psta" + status1)}
                    </div>
                    <div className="messegeMatch">
                      🎓 השכלה:
                      {getWordByGender("pedu" + education1)}
                    </div>
                    <div className="messegeMatch">
                      💼 עיסוק:
                      {occupation1}
                    </div>
                    <div className="messegeMatch">
                      🌍 מוצא:
                      {origin1}
                    </div>
                    <div className="whiteLine" />
                    <h5>🎨 🎸 התחביבים שלי</h5>
                    <div className="messegeMatch">
                      {hobbies1
                        .split(",")
                        .map((current) => getHobby(current))
                        .join(", ")}
                      {hobbieFree1 != null ? ", " : ""}
                      {hobbieFree1}
                    </div>
                  </div>
                  <div></div>
                </div>
                <div className="whiteLine" />
                <h5> טקסט חופשי</h5>
                {vipMatch1 == "0" ? (
                  "הטקסט מופיע כאשר אחד מהצדדים מנוי"
                ) : (
                  <div className="messegeMatch"> {freeText1}</div>
                )}
                {vipMatch1 == "1" ? (
                  <div>
                    <h5> טלפון לבירורים </h5>
                    <div className="messegeMatch"> {cellFriend1}</div>
                  </div>
                ) : null}
                <br />
                <h5> תמונות ועוד</h5>
                {vipMatch1 == "0" ? (
                  <div>
                    אופס... פרטים מלאים מופיעים אם אחד מהצדדים מנוי.
                    <div className="lbl_grey">
                      <Link
                        to="/Subscribe"
                        // style={{ textDecoration: "none" }}
                      >
                        {" לרכישת מנוי "}
                      </Link>
                      במחיר של כוס קפה חודשית
                    </div>
                    <div
                      className="lbl_grey"
                      onClick={() => setVipExplain(true)}
                    >
                      <u> למנויים יש יותר דייטים.</u>
                      (לא כל המנויים הם בתשלום)
                    </div>
                    <Popup
                      open={vipExplain}
                      contentStyle={{
                        minWidth: "30%",
                        maxWidth: "40%",
                        borderRadius: "5px",
                      }}
                      position="top center"
                      onClose={() => setVipExplain(false)}
                      closeOnDocumentClick
                      closeOnEscape
                    >
                      <div
                        className="exit-popup"
                        onClick={() => {
                          setVipExplain(false);
                        }}
                      >
                        x
                      </div>
                      <br />
                      <DateCount />
                      <br />
                    </Popup>
                  </div>
                ) : (
                  <div>
                    <div className="iconsline">
                      <div
                        className={
                          image1a != "" ? "cameraicon" : "cameraicongrey"
                        }
                        onClick={cameraBtnClick}
                      ></div>

                      <a
                        href={facebook1 != "" ? facebook1 : null}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className={
                            facebook1 != "" ? "faceicon" : "faceicongrey"
                          }
                          style={{ marginTop: "5px" }}
                          onClick={
                            facebook1 != "" ? facebookBtnClick : () => {}
                          }
                        ></div>
                      </a>

                      {/* <div
                        className={
                          facebook1 != "" ? "faceicon" : "faceicongrey"
                        }
                        onClick={facebook1 != "" ? facebookBtnClick : () => {}}
                      ></div> */}
                      <a
                        href={instegram1 != "" ? instegram1 : null}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className={
                            instegram1 != ""
                              ? "instegramicon"
                              : "instegramicongrey"
                          }
                          style={{ marginTop: "5px" }}
                          onClick={
                            instegram1 != "" ? instegramBtnClick : () => {}
                          }
                        ></div>
                      </a>
                      <a
                        href={linkedin1 != "" ? linkedin1 : null}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className={
                            linkedin1 != ""
                              ? "linkedinicon"
                              : "linkedinicongrey"
                          }
                          style={{ marginTop: "5px" }}
                          onClick={
                            linkedin1 != "" ? linkedinBtnClick : () => {}
                          }
                        ></div>
                      </a>

                      <div
                        // className="recordicon"
                        // onClick={recordBtnClick}

                        className={
                          recordingBlobURL1a !== ""
                            ? "recordicon"
                            : "recordicongrey"
                        }
                        onClick={
                          recordingBlobURL1a !== "" ? recordBtnClick : () => {}
                        }
                      ></div>
                    </div>
                    <div id="image1">
                      {image1a !== "" ? (
                        <>
                          {location.pathname.includes("/image1a") ? (
                            <img
                              className="image-fullscreen"
                              src={image1aBlobURL}
                              alt=""
                              onClick={(e) => imgClick("1a")}
                              id="img1a"
                            />
                          ) : (
                            <img
                              className="image-doubleMatch"
                              src={image1aBlobURL}
                              alt=""
                              onClick={(e) => imgClick("1a")}
                              id="img1a"
                            />
                          )}
                        </>
                      ) : null}
                      {image2a !== "" ? (
                        <>
                          {location.pathname.includes("/image2a") ? (
                            <img
                              className="image-fullscreen"
                              src={image2aBlobURL}
                              alt=""
                              onClick={(e) => imgClick("2a")}
                              id="img2a"
                            />
                          ) : (
                            <img
                              className="image-doubleMatch"
                              src={image2aBlobURL}
                              alt=""
                              onClick={(e) => imgClick("2a")}
                              id="img2a"
                            />
                          )}
                        </>
                      ) : null}
                      {image3a !== "" ? (
                        <>
                          {location.pathname.includes("/image3a") ? (
                            <img
                              className="image-fullscreen"
                              src={image3aBlobURL}
                              alt=""
                              onClick={(e) => imgClick("3a")}
                              id="img3a"
                            />
                          ) : (
                            <img
                              className="image-doubleMatch"
                              src={image3aBlobURL}
                              alt=""
                              onClick={(e) => imgClick("3a")}
                              id="img3a"
                            />
                          )}
                        </>
                      ) : null}
                      {image4a !== "" ? (
                        <>
                          {location.pathname.includes("/image4a") ? (
                            <img
                              className="image-fullscreen"
                              src={image4aBlobURL}
                              alt=""
                              onClick={(e) => imgClick("4a")}
                              id="img4a"
                            />
                          ) : (
                            <img
                              className="image-doubleMatch"
                              src={image4aBlobURL}
                              alt=""
                              onClick={(e) => imgClick("4a")}
                              id="img4a"
                            />
                          )}
                        </>
                      ) : null}
                      {image5a !== "" ? (
                        <>
                          {location.pathname.includes("/image5a") ? (
                            <img
                              className="image-fullscreen"
                              src={image5aBlobURL}
                              alt=""
                              onClick={(e) => imgClick("5a")}
                              id="img5a"
                            />
                          ) : (
                            <img
                              className="image-doubleMatch"
                              src={image5aBlobURL}
                              alt=""
                              onClick={(e) => imgClick("5a")}
                              id="img5a"
                            />
                          )}
                        </>
                      ) : null}
                    </div>
                    <div id="facebook1">
                      {"הקישור לפייס הוא: "}
                      <a
                        href={facebook1}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {facebook1}
                      </a>
                    </div>
                    <div id="instegram1">
                      {"הקישור לאינסטגרם הוא: "}
                      <a
                        href={instegram1}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {instegram1}
                      </a>
                    </div>
                    <div id="linkedin1">
                      {"הקישור ללינקדין הוא: "}
                      <a
                        href={linkedin1}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {linkedin1}
                      </a>
                    </div>
                    <div id="record1">
                      <div className="record-line">
                        <ReactPlayer
                          playing={isRecord1aPlaying}
                          autoPlay={false}
                          url={recordingBlobURL1a}
                          controls={true}
                          progressInterval={100}
                          config={{
                            file: {
                              forceAudio: true,
                            },
                          }}
                          onEnded={() => {
                            setIsRecord1aPlaying(false);
                          }}
                          width="0px"
                          height="0px"
                        />
                        <div Style="width: 33.3%">
                          <div
                            className={
                              "playicon-button " +
                              (isRecord1aPlaying ? "pause-icon" : "play-icon")
                            }
                            onClick={() => {
                              setIsRecord1aPlaying(!isRecord1aPlaying);
                            }}
                          ></div>
                          <p className="lbl">לשמיעה</p>
                        </div>
                        {myRecord1a == 1 ? "אני על עצמי" : "מספרים עלי"}
                        {record1a + dateRecord1a}
                      </div>
                    </div>
                    <br />
                    <div className="whiteLine" />

                    <div>
                      {msgIGot1.length > 1 ? (
                        <h5> קיבלת הודעה מ{firstName1}:</h5>
                      ) : (
                        ""
                      )}
                      <div className="messegeMatch">{msgIGot1}</div>
                    </div>
                    <div
                      className={
                        choice1 != "" && choice1Saved
                          ? "messegeMatch"
                          : "invisible"
                      }
                    >
                      <b>תשובת הצד השני: </b>
                      {getOtherChoice(otherResponse1)}
                    </div>
                  </div>
                )}
                <div className="response-container">
                  <div className="faceline">
                    <div style={{}}>
                      <div className="parent-choice">
                        <input
                          type="button"
                          id="good"
                          name="choice"
                          value="אשמח להכיר :)"
                          className={`choiceCube ${
                            choice1 === 1
                              ? "active"
                              : vipMatch1 == "0"
                              ? "greyChoiceCube"
                              : ""
                          }`}
                          onClick={(e) => handleChoice1(1)}
                        />
                        <input
                          type="button"
                          id="maybe"
                          name="choice"
                          value={
                            "סורי, לא פנוי" + (getGender() === "1" ? "ה" : "")
                          }
                          className={`choiceCube ${
                            choice1 === 2
                              ? "active"
                              : vipMatch1 == "0"
                              ? "greyChoiceCube"
                              : ""
                          }`}
                          onClick={(e) => handleChoice1(2)}
                        />
                        <input
                          type="button"
                          id="notgood"
                          name="choice"
                          value="תודה, פחות מתאים"
                          className={`choiceCube ${
                            choice1 === 3
                              ? "active"
                              : vipMatch1 == "0"
                              ? "greyChoiceCube"
                              : ""
                          }`}
                          onClick={(e) => handleChoice1(3)}
                        />
                      </div>
                      {vipMatch1 == "1" ? (
                        <div>
                          <div
                            className="messegeMatch"
                            Style={{
                              fontSize: "12px",
                              marginTop: "3px",
                              background: "#f8f8f8",
                            }}
                          >
                            💌 גם אם זה לא מתאים, שליחת תשובה תעזור לאחרים לדעת!
                            💌
                          </div>
                          <div
                            className="messegeMatch"
                            Style={"font-size:11px"}
                            onClick={() =>
                              setFreeTextAreaVisible(!freeTextAreaVisible)
                            }
                          >
                            <b>
                              {" "}
                              <u> רוצה להוסיף כמה מילים לצד השני? </u>
                            </b>
                            {msgForOtherSideLength1 + "/100 "}{" "}
                          </div>
                          <div
                            className={
                              freeTextAreaVisible
                                ? "freetext-container"
                                : "freetext-container invisible"
                            }
                          >
                            <textarea
                              Style={"height:40px"}
                              className="freetext"
                              rows="3"
                              maxLength="100"
                              value={
                                msgForOtherSide1 != ""
                                  ? msgForOtherSide1
                                  : msgISent1
                              }
                              onChange={(e) => handleText1(e.target.value)}
                            ></textarea>
                          </div>
                        </div>
                      ) : null}

                      {isErrorMessageDisplayed && (
                        <div className="error-message">
                          <h5>{errorMessageFirstChoice} </h5>
                        </div>
                      )}

                      {vipMatch1 == "1" ? (
                        <div>
                          <div
                            // className="button-text-center save_animation"
                            className={
                              !choice1Saved
                                ? "button-text-center save_animation active pulse"
                                : "button-text-center save_animation pulse"
                            }
                            id="btnSubmit"
                            onClick={() => {
                              localStorage.setItem("lastMatchChosen", 1);
                              onSubmit(matchId, choice1, msgForOtherSide1);
                            }}
                          >
                            שמירת התשובה
                          </div>
                          <div
                            className="messegeMatch"
                            style={{ marginTop: "9px" }}
                          >
                            * אם שניכם אומרים כן, המערכת שולחת מייל וסמס לשניכם
                            שאפשר להתקדם לדייט :)
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {/* <div>
                    <div className="rectangle animationDown"></div>
                    <div className="triangle-down animationDown"></div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="line" /> */}

            {/* <div className="infoicon" onClick={infoBtnClick2}></div> */}

            {isErrorMessageDisplayed && (
              <div className="error-message">
                <h5>{errorMessage} </h5>
              </div>
            )}

            {/* <div className="button-text-center" id="btnSubmit" onClick={onSubmit}>
          אישור
        </div> */}
            <br />

            {/* <a
              href="whatsapp://send/?text=הי מה המצב? אני רוצה להמליץ לך לנסות אתר ממש שווה, הוא שדכן חכם.. זה הקישור: https://double2love.com"
              className="prev-matches-button"
              style={{ color: "var(--btn-color)" }}
            >
              ו.. אפשר ללחוץ כאן ולשתף את האתר לחבר.ה, לכולנו יהיו יותר התאמות
              :)
            </a> */}
          </div>
        </>
      ) : (
        <DiceLoading />
      )}
    </div>
  );
};

export default GetFullMatch;
