import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
import "../../App.css";
import "./SuperMatchMaker.css";
import HeaderBack from "../../components/Header/HeaderBack";
import ManagePlace from "../../components/ManagePlace/ManagePlace";
import LastDataForMatchMaker from "../../components/LastDataForMatchMaker/LastDataForMatchMaker";

import axios from "axios";
import {
  getHobby,
  getSuperMatchMaker,
  getDoubleCounsler,
  getWomanDictionary,
  getManDictionary,
} from "../../handlers/dictionaryHandler.js";
import FileManager from "../../utils/FileManager";

// import login from "../login/login.component";

const SuperMatchMaker = () => {
  let history = useHistory();
  const [sendWhatsupp, setSendWhatsupp] = useState("");
  const [cellularToFind, setCellularToFind] = useState("");
  const [emailToFind, setEmailToFind] = useState("");
  const [idByMail, setIdByMail] = useState("");
  const [monthToAdd, setMonthToAdd] = useState();
  const [editedEmail, setEditedEmail] = useState("");
  const [editedCellular, setEditedCellular] = useState("");
  const [editedFirstName, setEditedFirstName] = useState("");
  const [editedLastName, setEditedLastName] = useState("");
  const [editedGender, setEditedGender] = useState("");
  const [showDelUserOption, setShowDelUserOption] = useState(false);
  const [showUpdatePassOption, setShowUpdatePassOption] = useState(false);

  const [userList, setUserList] = useState([]);
  const [totalRows, setTotalRows] = useState("");
  const [msgToUsers, setMsgToUsers] = useState("");
  const [details1, setDetails1] = useState("");
  const [freeText1, setfreeText1] = useState("");
  const [cellFriend1, setCellFriend1] = useState("");
  const [agepartner, setAgepartner] = useState("");
  const [tallpartner, setTallpartner] = useState("");
  const [distancepartner, setDistancepartner] = useState("");
  const [religouspartner, setReligouspartner] = useState("");
  const [statuspartner, setStatuspartner] = useState("");
  const [educationpartner, setEducationpartner] = useState("");
  const [image1a, setImage1a] = useState("");
  const [image2a, setImage2a] = useState("");
  const [image3a, setImage3a] = useState("");
  const [image4a, setImage4a] = useState("");
  const [image5a, setImage5a] = useState("");
  const [image1aBlobURL, setImage1aBlobURL] = useState("");
  const [image2aBlobURL, setImage2aBlobURL] = useState("");
  const [image3aBlobURL, setImage3aBlobURL] = useState("");
  const [image4aBlobURL, setImage4aBlobURL] = useState("");
  const [image5aBlobURL, setImage5aBlobURL] = useState("");
  const [image1aFullScreen, setImage1aFullScreen] = useState(false);
  const [image2aFullScreen, setImage2aFullScreen] = useState(false);
  const [image3aFullScreen, setImage3aFullScreen] = useState(false);
  const [image4aFullScreen, setImage4aFullScreen] = useState(false);
  const [image5aFullScreen, setImage5aFullScreen] = useState(false);
  const [facebook1, setFacebook1] = useState("");
  const [instegram1, setInstegram1] = useState("");
  const [religous1, setReligous1] = useState("");
  const [tall1, setTall1] = useState("");
  const [education1, setEducation1] = useState("");
  const [status1, setStatus1] = useState("");
  const [hobbies1, setHobbies1] = useState("");
  const [occupation1, setOccupation1] = useState("");
  const [origin1, setOrigin1] = useState("");
  const [id1, setId1] = useState("");
  const [cellular, setCellular] = useState("");
  const [partnerFace, setPartnerFace] = useState("");
  const [vipDetails, setVipDetails] = useState("");
  const [stopSendWhatsuppFromDb, setStopSendWhatsuppFromDb] = useState("");
  const [partnerShape, setPartnerShape] = useState("");
  const [m1details, setM1details] = useState("");
  const [m2details, setM2details] = useState("");
  const [m3details, setM3details] = useState("");
  const [m4details, setM4details] = useState("");
  const [m5details, setM5details] = useState("");
  const [record1a, setRecord1a] = useState("");
  const [myRecord1a, setMyRecord1a] = useState("");
  const [dateRecord1a, setDateRecord1a] = useState("");
  const [isRecord1aPlaying, setIsRecord1aPlaying] = useState(false);
  const [recordingBlobURL1a, setRecordingBlobURL1a] = useState("");
  const [cameraAreaVisible, setCameraAreaVisible] = useState(false);
  const [faceAreaVisible, setFaceAreaVisible] = useState(false);
  const [instegramAreaVisible, setInstegramAreaVisible] = useState(false);
  const [recordAreaVisible, setRecordAreaVisible] = useState(false);

  const [cellMatch, setCellMatch] = useState([]);

  const recordBtnClick = () => {
    setFaceAreaVisible(false);
    setCameraAreaVisible(false);
    setInstegramAreaVisible(false);
    setRecordAreaVisible(!recordAreaVisible);
  };

  const instegramBtnClick = () => {
    setFaceAreaVisible(false);
    setCameraAreaVisible(false);
    setInstegramAreaVisible(!instegramAreaVisible);
    setRecordAreaVisible(false);
  };

  const facebookBtnClick = () => {
    setFaceAreaVisible(!faceAreaVisible);
    setCameraAreaVisible(false);
    setInstegramAreaVisible(false);
    setRecordAreaVisible(false);
  };

  const cameraBtnClick = () => {
    setCameraAreaVisible(!cameraAreaVisible);
    setFaceAreaVisible(false);
    setInstegramAreaVisible(false);
    setRecordAreaVisible(false);
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const showUsers = async (path) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_LOCALHOST_ADDRESS + path,

        config
      );
      const userData = response.data;
      setTotalRows(userData.length);
      setUserList([...userData]);
    } catch (error) {
      // probebly user is not authenticated or server is down
    }
  };

  const showDetailsByCell = async (cell) => {
    try {
      console.log(cell);
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/getDetailsByCell",
        { cell },
        config
      );
      const userData = response.data;

      if (userData[0].length > 0) {
        setDetails1(userData[0][0].details);
        setfreeText1(userData[0][0].freeText);
        setCellFriend1(userData[0][0].cellFriend);
        setAgepartner(userData[0][0].agepartner);
        setTallpartner(userData[0][0].tallpartner);
        setDistancepartner(userData[0][0].distancepartner);
        setReligouspartner(userData[0][0].religouspartner);
        setStatuspartner(userData[0][0].statuspartner);
        setEducationpartner(userData[0][0].educationpartner);
        if (userData[0][0].keyImage1 !== "") {
          const image1Data = await FileManager.getFile(
            userData[0][0].keyImage1
          );
          setImage1a(image1Data.file);
          setImage1aBlobURL(image1Data.blobURL);
        }
        if (userData[0][0].keyImage2 !== "") {
          const image2Data = await FileManager.getFile(
            userData[0][0].keyImage2
          );
          setImage2a(image2Data.file);
          setImage2aBlobURL(image2Data.blobURL);
        }
        if (userData[0][0].keyImage3 !== "") {
          const image3Data = await FileManager.getFile(
            userData[0][0].keyImage3
          );
          setImage3a(image3Data.file);
          setImage3aBlobURL(image3Data.blobURL);
        }
        if (userData[0][0].keyImage4 !== "") {
          const image4Data = await FileManager.getFile(
            userData[0][0].keyImage4
          );
          setImage4a(image4Data.file);
          setImage4aBlobURL(image4Data.blobURL);
        }
        if (userData[0][0].keyImage5 !== "") {
          const image5Data = await FileManager.getFile(
            userData[0][0].keyImage5
          );
          setImage5a(image5Data.file);
          setImage5aBlobURL(image5Data.blobURL);
        }
        setFacebook1(userData[0][0].facebook);
        setInstegram1(userData[0][0].instegram);
        setReligous1(userData[0][0].religous);
        setTall1(userData[0][0].tall);
        setEducation1(userData[0][0].education);
        setStatus1(userData[0][0].status);
        setOccupation1(userData[0][0].occupation);
        setOrigin1(userData[0][0].origin);
        setPartnerFace(userData[0][0].partnerFace);
        setVipDetails(userData[0][0].vipDetails);
        setStopSendWhatsuppFromDb(userData[0][0].stopSendWhatsupp);
        setM1details(userData[0][0].m1details);
        setM2details(userData[0][0].m2details);
        setM3details(userData[0][0].m3details);
        setM4details(userData[0][0].m4details);
        setM5details(userData[0][0].m5details);
        setEditedCellular(userData[0][0].cellular);
        setEditedEmail(userData[0][0].email);
        setEditedFirstName(userData[0][0].firstName);
        setEditedLastName(userData[0][0].lastName);
        setEditedGender(userData[0][0].gender);
        setPartnerShape(userData[0][0].partnerShape);
        setId1(userData[0][0].id);
        setCellular(userData[0][0].cellular);
        setHobbies1(userData[0][0].hobbies);
        if (userData[0][0].rec1 !== "") {
          const rec1aBuffer = userData[0][0].rec1
            .split(",,")
            .map(
              (current) =>
                new Int8Array(current.split(",").map((current) => +current))
            );
          const blob1a = new Blob(rec1aBuffer, {
            type: "audio/mp3",
          });
          const blob1aURL = URL.createObjectURL(blob1a);
          setRecordingBlobURL1a(blob1aURL);
        }
        // setRecordingBlob(blob1a);
        setMyRecord1a(userData[0][0].myRec1);
      } else {
        // in case there is no data
      }
    } catch (error) {
      // probebly user is not authenticated or server is down
    }
  };

  const showIdByEmail = async (emailToFind) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PgetIdByEmail",
        {
          emailToFind: emailToFind,
        },
        config
      );
      const userData = response.data;
      console.log("userData", userData[0]);
      setIdByMail(userData[0][0].id);
    } catch (error) {
      console.log(error);
    }
  };

  const stopSendWhatsapp = async (toStop) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PstopSendWhatsapp",
        {
          cell: cellularToFind,
          toStop: toStop,
        },
        config
      );
      if (response.data === true) {
        alert("done toStop " + toStop);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const blockUser = async (toBlock) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PblockUser",
        {
          cell: cellularToFind,
          toBlock: toBlock,
        },
        config
      );
      if (response.data === true) {
        alert("done blockUser " + toBlock);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const activeUser = async (activeState) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PactiveUser",
        {
          cell: cellularToFind,
          activeState: activeState,
        },
        config
      );
      if (response.data === true) {
        alert("done activeState " + activeState);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const delUser = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PdelUser",
        {
          cell: cellularToFind,
        },
        config
      );
      if (response.data === true) {
        alert("done delUser ");
        setShowDelUserOption(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updatePass = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PupdatePassSuper",
        {
          cell: cellularToFind,
        },
        config
      );
      if (response.data === true) {
        alert("done updatePass to abcd1234 ");
        setShowUpdatePassOption(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const vipGift = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PvipGift",
        {
          cell: cellularToFind,
          mnth: monthToAdd,
        },
        config
      );
      if (response.data === true) {
        alert("done vipGift " + monthToAdd);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveEditedSignUpDetails = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PeditSignUpDetails",
        {
          cell: cellularToFind,
          editedCellular,
          editedEmail,
          editedFirstName,
          editedLastName,
          editedGender,
        },
        config
      );
      if (response.data[0].res == 0) {
        alert("done");
      } else {
        alert(
          "יש בעיה בפרטים,נדרש: 1. לעדכן את המשתמש 2. להעביר לבדיקה יסודית בפיתוח"
        );
      }
    } catch (error) {
      console.log(error);
    }
    // console.log(response.data[0][0]);
  };

  const showCellsMatches = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PshowCellsMatches",

        config
      );
      const userData = response.data;
      setCellMatch([...userData]);
    } catch (error) {
      // probebly user is not authenticated or server is down
    }
  };

  return (
    <form className="supermatchmaker-form">
      <HeaderBack />
      <br />
      <div
        className={
          getDoubleCounsler() === "0" ? "invisible" : "whitebackground"
        }
      >
        <input
          type="text"
          placeholder="מס טלפון לשליחת ווטסאפ"
          onChange={(e) => setSendWhatsupp(e.target.value)}
        ></input>

        <a
          href={"https://wa.me/972" + sendWhatsupp}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="whatsuplink"></div>
        </a>
      </div>
      <div
        className={
          getSuperMatchMaker() === "0" ? "invisible" : "whitebackground"
        }
      >
        <div className="line" />
        <div className="h5middle">הצגת שם וטלפון לפי סינון</div>
        <div
          className="button-small-letters"
          onClick={() => {
            setMsgToUsers(
              "\n" +
                "! כאן דאבל, " +
                "קיבלת התאמה באתר יש להכנס ולסמן את התשובה " +
                ":) \n" +
                "שימו לב - גם הצד השני קיבל אתכם וממתין לתשובה " +
                "\n\n" +
                " Double2love.com " +
                "\n\n" +
                "להסרה מהתראות ווטסאפ השיבו הסר "
            );
            showUsers("/PcellDidntSeeMatchMatchMaker");
          }}
        >
          משתמשים שאמרו להם כן והם לא ראו
        </div>

        <div
          className="button-small-letters"
          onClick={() => {
            setMsgToUsers(
              "\n" +
                "! כאן דאבל, " +
                "ראינו שחסרים לך פרטים באתר על מנת לקבל התאמות." +
                " יש לבדוק בפרט את 'מי אני' בעמוד 3." +
                " ו'מי מתאים לי' עמוד 2." +
                " הנה קישור: " +
                "   Double2love.com "
            );
            showUsers("/PHaredUsersDidntFinish");
          }}
        >
          משתמשים חרדים שלא סיימו למלא פרטים
        </div>

        <div
          className="button-small-letters"
          onClick={() => {
            setMsgToUsers(
              "\n" +
                "!כאן דאבל, " +
                "ראינו ששבוע שעבר עניתם כן להצעה מההאתר." +
                " רצינו לשאול אם יש משהו שתרצו לשתף, או האם נפגשתם, וכו," +
                "  כמובן רק אם מתאים לך לשתף.." +
                "המשך יום טוב :)"
            );
            showUsers("/PcellsDatesBeforeWeek");
          }}
        >
          משתמשים ששבוע שעבר שניהם ענו כן
        </div>

        <div>הודעה: </div>
        <div className="iconsline">{msgToUsers}</div>

        <div className="userline">
          <div> סהכ: {totalRows}</div>
          <div> שם |</div>
          <div> טלפון |</div>
          <div> גיל |</div>
          <div> מנוי? |</div>
          <div> קישור </div>
        </div>

        {userList.map((curUser) => {
          return (
            <div key={curUser.cellular} style={{ height: "auto" }}>
              <div className="userline">
                <div>{curUser.rowNum} |</div>
                <div>{curUser.fullName} |</div>
                <div>{curUser.cellular} |</div>
                <div>{curUser.age} |</div>
                <div>{curUser.membership === 1 ? "V" : ""} |</div>

                <div className="whatsupFloata">
                  <a
                    href={`https://wa.me/972${curUser.cellular}?text=הי ${curUser.firstName} ${msgToUsers}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="whatsupTips" />
                  </a>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div
        className={
          getDoubleCounsler() === "0" ? "invisible" : "whitebackground"
        }
      >
        <div className="line" />
        <div className="h5middle">מציאת מזהה משתמש לפי אימייל</div>
        <input
          type="text"
          placeholder="אימייל מזהה לחיפוש"
          // maxLength={10}
          onChange={(e) => setEmailToFind(e.target.value)}
        ></input>
        <div className="faceline">
          <div
            className="stopWhatsappBtn"
            style={{ height: "25px" }}
            onClick={() => {
              showIdByEmail(emailToFind);
            }}
          >
            הצג מספר
          </div>
          <input
            type="text"
            style={{ width: "80px", fontSize: "14px" }}
            value={idByMail}
          ></input>
        </div>
        <div className="h5middle">הצגת כרטיס לפי מס טלפון</div>
        <input
          type="text"
          placeholder="טלפון מזהה לחיפוש"
          maxLength={10}
          onChange={(e) => setCellularToFind(e.target.value)}
        ></input>

        <div
          className="button-text-center"
          onClick={() => {
            showDetailsByCell(cellularToFind);
          }}
        >
          הצג פרטים
        </div>
        <div className="faceline">
          <div
            className="stopWhatsappBtn"
            onClick={() => {
              stopSendWhatsapp("1");
            }}
          >
            להפסיק לשלוח ווטסאפ
          </div>
          <div
            className="stopWhatsappBtn"
            onClick={() => {
              stopSendWhatsapp("0");
            }}
          >
            לחזור לשלוח ווטסאפ
          </div>
          <div
            className="stopWhatsappBtn"
            onClick={() => {
              blockUser("1");
            }}
          >
            חסימת משתמש
          </div>
          <div
            className="stopWhatsappBtn"
            onClick={() => {
              blockUser("0");
            }}
          >
            ביטול חסימה
          </div>
        </div>
        <div className="faceline">
          <div
            className="stopWhatsappBtn"
            onClick={() => {
              activeUser("0");
            }}
          >
            השהיית שימוש
          </div>
          <div
            className="stopWhatsappBtn"
            onClick={() => {
              activeUser("1");
            }}
          >
            לחזור לשימוש
          </div>
          <input
            type="text"
            placeholder="חודשים"
            maxLength={2}
            style={{ width: "40px" }}
            onChange={(e) => setMonthToAdd(e.target.value)}
          ></input>
          <div
            className="stopWhatsappBtn"
            onClick={() => {
              vipGift();
            }}
          >
            מנוי מתנה X חודשים
          </div>
        </div>
        <div className="faceline">
          <div
            className="stopWhatsappBtn"
            onClick={() => {
              setShowDelUserOption(true);
            }}
          >
            מחיקת משתמש
          </div>
          <div
            className={showDelUserOption ? "stopWhatsappBtn" : "invisible"}
            onClick={() => {
              delUser();
            }}
          >
            כן למחוק!
          </div>
          <div
            className="stopWhatsappBtn"
            onClick={() => {
              setShowUpdatePassOption(true);
            }}
          >
            עדכון סיסמה דיפולטיבית
          </div>
          <div
            className={showUpdatePassOption ? "stopWhatsappBtn" : "invisible"}
            onClick={() => {
              updatePass();
            }}
          >
            כן לעדכן!
          </div>
        </div>
        <div className="faceline">
          <input
            type="text"
            maxLength={10}
            value={editedCellular}
            style={{ fontSize: "12px", width: "80px" }}
            onChange={(e) => setEditedCellular(e.target.value)}
          ></input>
          <input
            type="text"
            maxLength={45}
            value={editedEmail}
            style={{ fontSize: "12px" }}
            onChange={(e) => setEditedEmail(e.target.value)}
          ></input>
        </div>
        <div className="messegeMatch">שם פרטי | שם משפחה | 1-אישה 2-גבר</div>
        <div className="faceline">
          <input
            type="text"
            maxLength={15}
            value={editedFirstName}
            style={{ fontSize: "12px", width: "80px" }}
            onChange={(e) => setEditedFirstName(e.target.value)}
          ></input>
          <input
            type="text"
            maxLength={15}
            value={editedLastName}
            style={{ fontSize: "12px" }}
            onChange={(e) => setEditedLastName(e.target.value)}
          ></input>
          <input
            type="text"
            maxLength={2}
            value={editedGender}
            style={{ fontSize: "12px" }}
            onChange={(e) => setEditedGender(e.target.value)}
          ></input>
          <div
            className="stopWhatsappBtn"
            onClick={() => {
              saveEditedSignUpDetails();
            }}
          >
            שמירת פרטי הרשמה
          </div>
        </div>
        <div
          className="button-small-letters"
          style={{ backgroundColor: "rgb(56, 205, 56)" }}
        >
          {" "}
          מותר לשלוח ווטסאפ? {stopSendWhatsuppFromDb == 1 ? "לא!" : "כן"}
        </div>
        <div>
          <div
            className="button-text-center"
            id="btnMatch"
            //   onClick={infoBtnClick}
          >
            {details1}
          </div>

          <div></div>
          <h5> קצת עובדות טכניות</h5>
          <div className="messegeMatch">
            {editedGender == "1"
              ? getManDictionary("pdat" + religous1)
              : getWomanDictionary("pdat" + religous1)}{" "}
            , {tall1} ס'מ ,{" "}
            {editedGender == "1"
              ? getManDictionary("psta" + status1)
              : getWomanDictionary("psta" + status1)}
          </div>
          <div className="messegeMatch">
            השכלה:
            {editedGender == "1"
              ? getManDictionary("pedu" + education1)
              : getWomanDictionary("pedu" + education1)}
          </div>
          <div className="messegeMatch">
            עיסוק:
            {occupation1}
          </div>
          <div className="messegeMatch">
            מוצא:
            {origin1}
          </div>
          <h5> תחביבים</h5>
          <div className="messegeMatch">
            {hobbies1
              .split(",")
              .map((current) => getHobby(current))
              .join(", ")}
          </div>
          <h5> טקסט חופשי</h5>
          <div className="messegeMatch" style={{ maxWidth: "92%" }}>
            {" "}
            {freeText1}
          </div>
          <h5> טלפון לבירורים </h5>
          <div className="messegeMatch"> {cellFriend1}</div>
          <h5> גילאים פרטנר </h5>
          <div className="messegeMatch"> {agepartner}</div>
          <h5> גובה פרטנר </h5>
          <div className="messegeMatch"> {tallpartner}</div>
          <h5> עד מרחק </h5>
          <div className="messegeMatch"> {distancepartner} ק"מ</div>
          <h5> דת פרטנר </h5>
          <div className="messegeMatch">
            {" "}
            {religouspartner
              .split(",")
              .map((current) =>
                editedGender == "1"
                  ? getWomanDictionary("pdat" + current)
                  : getManDictionary("pdat" + current)
              )
              .join(", ")}
          </div>
          <h5> סטטוס פרטנר </h5>
          <div className="messegeMatch">
            {" "}
            {statuspartner
              .split(",")
              .map((current) =>
                editedGender == "1"
                  ? getWomanDictionary("psta" + current)
                  : getManDictionary("psta" + current)
              )
              .join(", ")}
          </div>
          <h5> השכלה פרטנר </h5>
          <div className="messegeMatch">
            {" "}
            {educationpartner
              .split(",")
              .map((current) =>
                editedGender == "1"
                  ? getManDictionary("pedu" + current)
                  : getWomanDictionary("pedu" + current)
              )
              .join(", ")}
          </div>
          <h5>פנים ומבנה פרטנר</h5>
          <div className="messegeMatch">
            {" "}
            {"face: " + partnerFace + "|" + partnerShape}
          </div>
          <h5>טלפון משתמש | מספר סידורי | פעיל|חסום</h5>
          <div className="messegeMatch"> {cellular + "|" + id1}</div>
          <div className="messegeMatch">
            {" "}
            {"vip, date, route, gift: " + vipDetails}
          </div>
          <h5>התאמות קודמות</h5>
          <div className="messegeMatch">
            {"Id, Woman, Man, Date, womanRes, manRes, womanSee, manSee, "}
            <br />
            {"row1: " + m1details}
            <br />
            {"row2: " + m2details}
            <br />
            {"row3: " + m3details}
            <br />
            {"row4: " + m4details}
            <br />
            {"row5: " + m5details}
          </div>
          <br />
          <h5> תמונות ועוד</h5>

          <div className="iconsline">
            <div
              className={
                image1a != "" || image2a != "" ? "cameraicon" : "cameraicongrey"
              }
              onClick={cameraBtnClick}
            ></div>
            <div
              className={facebook1 != "" ? "faceicon" : "faceicongrey"}
              onClick={facebook1 != "" ? facebookBtnClick : () => {}}
            ></div>
            <div
              className={
                instegram1 != "" ? "instegramicon" : "instegramicongrey"
              }
              onClick={instegram1 != "" ? instegramBtnClick : () => {}}
            ></div>
            <div
              // className="recordicon"
              // onClick={recordBtnClick}

              className={
                recordingBlobURL1a !== "" ? "recordicon" : "recordicongrey"
              }
              onClick={recordingBlobURL1a !== "" ? recordBtnClick : () => {}}
            ></div>
          </div>
          <div
            className={cameraAreaVisible ? "areaAboutMeCard " : " invisible "}
          >
            {image1a !== "" ? (
              <>
                <img
                  className={
                    image1aFullScreen ? "image-fullscreen" : "image-doubleMatch"
                  }
                  src={image1aBlobURL}
                  alt=""
                  onClick={(e) => setImage1aFullScreen(!image1aFullScreen)}
                />
              </>
            ) : null}
            {image2a !== "" ? (
              <>
                <img
                  className={
                    image2aFullScreen ? "image-fullscreen" : "image-doubleMatch"
                  }
                  src={image2aBlobURL}
                  alt=""
                  onClick={(e) => setImage2aFullScreen(!image2aFullScreen)}
                />
              </>
            ) : null}
            {image3a !== "" ? (
              <>
                <img
                  className={
                    image3aFullScreen ? "image-fullscreen" : "image-doubleMatch"
                  }
                  src={image3aBlobURL}
                  alt=""
                  onClick={(e) => setImage3aFullScreen(!image3aFullScreen)}
                />
              </>
            ) : null}
            {image4a !== "" ? (
              <>
                <img
                  className={
                    image4aFullScreen ? "image-fullscreen" : "image-doubleMatch"
                  }
                  src={image4aBlobURL}
                  alt=""
                  onClick={(e) => setImage4aFullScreen(!image4aFullScreen)}
                />
              </>
            ) : null}
            {image5a !== "" ? (
              <>
                <img
                  className={
                    image5aFullScreen ? "image-fullscreen" : "image-doubleMatch"
                  }
                  src={image5aBlobURL}
                  alt=""
                  onClick={(e) => setImage5aFullScreen(!image5aFullScreen)}
                />
              </>
            ) : null}
          </div>

          <div className={faceAreaVisible ? "areaAboutMeCard " : " invisible "}>
            {"הקישור לפייס הוא: "}
            <a href={facebook1} target="_blank" rel="noopener noreferrer">
              {facebook1}
            </a>
          </div>
          <div
            className={
              instegramAreaVisible ? "areaAboutMeCard " : " invisible "
            }
          >
            {"הקישור לאינסטגרם הוא: "}
            <a href={instegram1} target="_blank" rel="noopener noreferrer">
              {instegram1}
            </a>
          </div>

          <div
            className={recordAreaVisible ? "areaAboutMeCard " : " invisible "}
          >
            <div className="record-line">
              {/* <div className="playicon"></div>
                  <div className="stopplayicon"></div> */}
              <ReactPlayer
                playing={isRecord1aPlaying}
                autoPlay={false}
                url={recordingBlobURL1a}
                controls={true}
                progressInterval={100}
                config={{
                  file: {
                    forceAudio: true,
                  },
                }}
                onEnded={() => {
                  setIsRecord1aPlaying(false);
                }}
                width="0px"
                height="0px"
              />
              <div style={{ width: "33.3%" }}>
                <div
                  className={
                    "playicon-button " +
                    (isRecord1aPlaying ? "pause-icon" : "play-icon")
                  }
                  onClick={() => {
                    setIsRecord1aPlaying(!isRecord1aPlaying);
                  }}
                ></div>
                <p className="lbl">לשמיעה</p>
              </div>
              {myRecord1a == 1 ? "אני על עצמי" : "מספרים עלי"}
              {record1a + dateRecord1a}
            </div>
          </div>
          <br></br>
        </div>
      </div>
      <div
        className={
          getSuperMatchMaker() === "0" ? "invisible" : "whitebackground"
        }
      >
        <div className="line" />
        <LastDataForMatchMaker />
        <div className="line" />
        <ManagePlace />
        <div
          className="button-small-letters"
          onClick={() => {
            showCellsMatches();
          }}
        >
          טלפונים שקיבלו דייט - לשלוח ווטסאפ
        </div>

        {cellMatch.map((cur) => {
          return (
            <div key={cur.cellular} style={{ height: "auto" }}>
              <div className="userline">
                <div>
                  {cur.firstName},{cur.cellular}
                </div>
              </div>
            </div>
          );
        })}

        <div className="line" />

        <Link to="/questionnaires" style={{ textDecoration: "none" }}>
          <div className="button whiteBackNBorder" id="backBtn">
            {" "}
            <b>→</b>
            חזרה
          </div>
        </Link>
      </div>
    </form>
  );
};

export default SuperMatchMaker;
