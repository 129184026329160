import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import "./Homepage.css";
import AboutHelper from "../../components/AboutHelper/AboutHelper";
import TellAboutUsHelper from "../../components/TellAboutUsHelper/TellAboutUsHelper";
import ContactUsHelper from "../../components/ContactUsHelper/ContactUsHelper";
import homepagevideo from "../../videos/homepagevideo.mp4";
import HeaderHomepage from "./../../components/HeaderHomepage/HeaderHomepage";
import Footer from "./../../components/Footer/Footer";
import QandA from "../../components/Q&A/Q&A";
import StoryTelling from "../../components/StoryTelling/StoryTelling";
import Bi from "../../components/BI/Bi";

const Homepage = () => {
  let history = useHistory();

  const logInClicked = async () => {
    history.push("/login");
  };

  const signUpClicked = () => {
    history.push("/signup");
  };

  return (
    <div className="full-screen-homepage">
      {" "}
      {/*&& chk === '/Questionnaires' } */}{" "}
      <div className="">
        <HeaderHomepage />
        <h3 className="h-nomargin title-up-banner">
          אלפי משתמשים מאות דייטים וגם חתונות! הצטרפו וקבלו חודש מתנה.
        </h3>
        {/* <div className="hp-logo" /> */}
        <form
          className="homepage-form"
          onClick={() => {
            if (document.getElementById("slidepanel").style.width === "200px") {
              document.getElementById("slidepanel").style.width = "0px";
            }
          }}
        >
          <div>
            <video autoPlay loop muted className="videohomepage">
              <source src={homepagevideo} type="video/mp4" />
            </video>
          </div>
          <h3 className="h1-homepage">
            רוצה להכיר באופן ממוקד ורציני, תוך שמירה על הפרטיות שלך?
          </h3>
          <h3 className="h-nomargin">
            <b>דאבל הכרויות הוא האתר בשבילך!</b>
          </h3>
          <h3 className="h-nomargin">
            שידוך בהתאמה אישית באמצעות{" "}
            <b>
              <u>אלגוריתם</u>
            </b>{" "}
            חכם.
          </h3>

          <div className="flach">מתאים לדתיים, לחרדים, לדתלשים</div>

          <div
            className="signup-homepage shadow margintop18"
            onClick={signUpClicked}
          >
            {/* להצטרפות - חודש חינם! */}
            תנסו, מקסימום תינשאו ❤️
          </div>
          {/* <div className="homepage-photo"></div> */}

          <br />
          {/* <div className="container_color2">
            <h3 className="h-nomargin-bold margintop18"> החזון שלנו</h3>

            <div className="hazon max_width_fullscreen">
              1.
              <b>להביא התאמות לחברי האתר.</b>
              <br />
              באופן כמה שיותר שיוויוני ומכבד,
              <br />
              ללא קשר לסביבה החברתית שלהם היום.
              <br />
              <br />
              2. לנצל את הטכנולוגיה, הכישורים, והחוויה שלנו ולהביא התאמות
              ממוקדות ובסגנון, שבעזרת ה יובילו
              <b> לקשר יציב ואף לחתונה.</b>
              <br />
              <br />
              3. להביא את ההצעות אליך,
              <br />
              <b> כי מגיע לך. יגיע עד אליך.</b>
         
            </div>
            <br />
          </div> */}

          <div className="container_color1">
            <h3 className="h-nomargin-bold margintop18 ">אודות </h3>
            <StoryTelling />
            <br />
          </div>

          <div className="container_color2">
            <h3 className="h-nomargin-bold margintop18"> כותבים לנו</h3>
            <TellAboutUsHelper />
          </div>

          <div className="container_color1">
            <h3 className="h-nomargin-bold margintop18"> מספרים מספרים</h3>
            <Bi />
          </div>
          <br />

          <br />
          <div className="Footer sign-up-free" onClick={signUpClicked}>
            להצטרפות חודש חינם!
            <div
              class="heart-icon"
              style={{
                width: "30px",
                height: "30px",
                top: "-20px",
                right: "16px",
              }}
            ></div>
          </div>

          <div className="contactUsHelper faceline">
            <a
              href="https://www.facebook.com/%D7%93%D7%90%D7%91%D7%9C-%D7%94%D7%9B%D7%A8%D7%95%D7%99%D7%95%D7%AA-103072662171430"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="facebook "
                style={{ height: "24px", width: "26px" }}
              ></div>
            </a>
            <a
              href="https://instagram.com/double2love.ai?igshid=MzNlNGNkZWQ4Mg=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="instegramrealicon "
                style={{ height: "24px", width: "26px" }}
              ></div>
            </a>

            <a
              href={"https://wa.me/972549624084"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="whatsupp "
                style={{ height: "28px", width: "28px" }}
              ></div>
            </a>

            <a
              href={"mailto: doublelo2love@gmail.com"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="gmail "
                style={{ height: "20px", width: "27px" }}
              ></div>
            </a>
          </div>

          <br />

          <div
            className="faceline"
            style={{
              borderBottom: "1px solid #cccccc",
              borderTop: "1px solid #cccccc",
              maxWidth: "var(--max_width_fullscreen)",
            }}
          >
            <div className="lbl">בסיוע ויעוץ של: מט"י</div>
            <div className="mati"></div>
            <div className="lbl">ומעו"ף</div>
            <div className="maof"></div>
          </div>
          <div
            className="faceline"
            style={{
              borderBottom: "1px solid #cccccc",
              maxWidth: "var(--max_width_fullscreen)",
            }}
          >
            <div className="lbl">ובמעודדים: תכירו</div>
            <div className="taciru"></div>
            <div className="lbl">ובאים בטוב</div>
            <div className="goodvibes"></div>
          </div>
          <br />
          <div className="faceline">
            <div className="lbl">כל הזכויות שמורות</div>
            <div className="co"></div>
          </div>
          <br />
          {/* <Footer /> */}
        </form>{" "}
      </div>
    </div>
  );
};

export default Homepage;
