import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import "./AddPlace.css";
import HeaderBack from "../../components/Header/HeaderBack";
import axios from "axios";
import Files from "react-files";
import Resizer from "react-image-file-resizer";

const AddPlace = () => {
  let history = useHistory();
  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [region, setRegion] = useState("");
  const [stage, setStage] = useState("");
  const [kind, setKind] = useState("");
  const [isItFree, setIsItFree] = useState(false);
  const [imgplace, setImgplace] = useState("");
  const [imagesCounter, setImagesCounter] = useState(0);
  const [title, setTitle] = useState("");
  const [waze, setWaze] = useState("");
  const [freetext, setFreetext] = useState("");
  const [freeTextLength, setFreeTextLength] = useState("");

  const [isShareStatus, setIsShareStatus] = useState(false);
  const [isReccomendFriend, setIsReccomendFriend] = useState(false);
  const [isReccomedPlace, setIsReccomedPlace] = useState(false);
  const [isFew, setIsFew] = useState(false);
  const [cupon, setCupon] = useState("");

  const resizeFile = (file) =>
    new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        1920,
        1920,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
      Resizer.onerror = (error) => {
        reject(error);
      };
    });

  const onImagesSelected = async (files) => {
    // console.log("heree ");
    setImagesCounter(files.length);

    if (files.length - imagesCounter >= 2) {
      const image1 = files[files.length - 2];
      // console.log("1: " + image1);
      const image1InBase64 = await resizeFile(image1);
      setImgplace(image1InBase64);

      if (files[files.length - 1]) {
        const image2 = files[files.length - 1];
        // console.log("2: " + image2);
        const image2InBase64 = await resizeFile(image2);
        setImgplace(image2InBase64);
      }
    } else {
      const newImage = files[files.length - 1];
      const newImageInBase64 = await resizeFile(newImage);
      if (imgplace == "") {
        setImgplace(newImageInBase64);
      } else {
        setImgplace(newImageInBase64);
      }
    }
  };

  const imgClick = (imgId) => {
    if (document.getElementById(imgId).style.width != "240px") {
      document.getElementById(imgId).style.maxHeight = "290px";
      document.getElementById(imgId).style.width = "240px";
    } else {
      document.getElementById(imgId).style.maxHeight = "100px";
      document.getElementById(imgId).style.width = "100px";
    }
  };

  const handleText = (text) => {
    setFreetext(text);
    setFreeTextLength(text.length);
  };

  const alertUser = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessage(message);
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const onSubmitAddPlace = async () => {
    if (region != "") {
      if (stage != "0") {
        if (kind != "") {
          if (imgplace != "") {
            if (title != "") {
              if (waze.length > 5) {
                if (freetext.length > 60) {
                  try {
                    const response = await axios.post(
                      process.env.REACT_APP_LOCALHOST_ADDRESS + "/PaddPlace",
                      {
                        region,
                        stage,
                        kind,
                        isItFree: isItFree ? "1" : "0",
                        imgplace,
                        title,
                        waze,
                        freetext,
                      },
                      config
                    );
                    if (response.data === true) {
                      alertUser(
                        "נשמר בהצלחה,  תודה על ההמלצה! כעת היא ממתינה לאישור לפני שתוצג לכולם."
                      );
                      setRegion("");
                      setStage("");
                      setKind("");
                      setIsItFree(false);
                      setImgplace("");
                      setImagesCounter(0);
                      setTitle("");
                      setWaze("");
                      setFreetext("");
                      setFreeTextLength("");
                    } else {
                      alertUser(
                        "יש שגיאה, יתכן שהתמונה שצירפת גדולה מידי, נסו תמונה אחרת"
                      );
                    }
                  } catch (error) {
                    console.log(error);
                    alertUser(
                      "יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית"
                    );
                  }
                  // console.log(response.data[0][0]);
                } else {
                  alertUser("תיאור המקום קצר מידי");
                  console.log("Hobby did not set");
                }
              } else {
                alertUser("מה לרשום בוויז קצר מידי");
                console.log("Hobby did not set");
              }
            } else {
              alertUser("לא רשמת כותרת ");
              console.log("Hobby did not set");
            }
          } else {
            alertUser("לא העלית תמונה");
            console.log("Hobby did not set");
          }
        } else {
          alertUser("לא בחרת סגנון");
          console.log("Education did not set");
        }
      } else {
        alertUser("לא בחרת שלב בקשר ");
        console.log("stage not set");
      }
    } else {
      alertUser("לא בחרת איזור בארץ");
      console.log("REGION did not chosen");
    }
  };

  return (
    <div className="aboutdouble-full-screen">
      <HeaderBack />
      <br />
      <form className="addplace-form">
        <h2 className="h-nomargin"> לאן יוצאים? מקום חדש! </h2>
        <div className="section-title">
          <b>מוסיפים מקום ומקבלים 5 נקודות!</b>
        </div>

        <div className="lbl">
          <b>
            ו... לפני שאתם מוסיפים מקום חדש - תבדקו שהוא לא קיים כבר
            <Link to="/findplace">{" כאן "}</Link>
          </b>
        </div>
        <br />

        <div className="section-title">איזור בארץ</div>
        <div className="parent-edu">
          <input
            type="button"
            className={`region ${region === "1" ? "active" : ""}`}
            value={"צפון"}
            key="1"
            onClick={(e) => setRegion("1")}
          />
          <input
            type="button"
            className={`region ${region === "2" ? "active" : ""}`}
            value={"דרום"}
            key="2"
            onClick={(e) => setRegion("2")}
          />
          <input
            type="button"
            className={`region ${region === "3" ? "active" : ""}`}
            value={"מרכז"}
            key="3"
            onClick={(e) => setRegion("3")}
          />
          <input
            type="button"
            className={`region ${region === "4" ? "active" : ""}`}
            value={"ירושלים"}
            key="4"
            onClick={(e) => setRegion("4")}
          />
        </div>
        <br />
        <div className="section-title">שלב בקשר </div>
        <div className="parent-edu">
          <input
            type="button"
            className={`region stage ${stage === "1" ? "active" : ""}`}
            value={"התחלה "}
            key="1"
            onClick={(e) => setStage("1")}
          />
          <input
            type="button"
            className={`region stage ${stage === "2" ? "active" : ""}`}
            value={"דייט מתקדם"}
            key="2"
            onClick={(e) => setStage("2")}
          />
        </div>
        <br />
        <div className="section-title">סגנון </div>
        <div className="parent-edu">
          <input
            type="button"
            className={`region ${kind === "1" ? "active" : ""}`}
            value={"טבע "}
            key="1"
            onClick={(e) => setKind("1")}
          />
          <input
            type="button"
            className={`region ${kind === "2" ? "active" : ""}`}
            value={"אוכל "}
            key="2"
            onClick={(e) => setKind("2")}
          />
          <input
            type="button"
            className={`region ${kind === "3" ? "active" : ""}`}
            value={"אקסטרים "}
            key="3"
            onClick={(e) => setKind("3")}
          />
          <input
            type="button"
            className={`region ${kind === "4" ? "active" : ""}`}
            value={"פעילות "}
            key="4"
            onClick={(e) => setKind("4")}
          />
        </div>
        <br />
        <div className="section-title">האם המקום בחינם? </div>
        <br />
        <div className="toggle-line">
          <label className="toggle">
            <input
              type="checkbox"
              onChange={() => {
                setIsItFree(!isItFree);
              }}
              checked={isItFree}
            />
            <span className="slider-in-toggle"></span>
          </label>

          <div className="lbl">{isItFree ? "כן, המקום בחינם" : "לא"}</div>
        </div>
        <br />
        <div className="section-title">תמונה מהמקום </div>
        <div className="files-dropzone">
          <div className="images-drop-area-container">
            <Files
              // className="files-dropzone"
              onChange={onImagesSelected}
              onError={(error) => {
                console.log(error);
              }}
              accepts={["image/png", ".jpeg", ".jpg", ".webpg"]}
              multiple
              maxFileSize={10000000}
              minFileSize={0}
              clickable
              className="images-drop-area"
            >
              גרור או לחץ להעלאת תמונה
            </Files>
          </div>
        </div>

        <div className="faceline">
          {/* key={image2} */}
          {imgplace !== "" ? (
            <>
              <img
                className="chosen-image"
                src={`${imgplace}`}
                id="imgplace"
                onClick={(e) => imgClick("imgplace")}
              />
              <h4
                className="remove-image-button"
                onClick={() => {
                  // console.log("Image 1 delete");
                  setImgplace("");
                }}
              ></h4>
            </>
          ) : null}
        </div>
        <br />
        <div className="section-title">כותרת </div>
        <textarea
          className="titleplace"
          rows="1"
          value={title}
          maxLength="30"
          onChange={(e) => setTitle(e.target.value)}
        ></textarea>
        <br />

        <div className="section-title">עיר/מה לכתוב בוויז?</div>
        <textarea
          className="titleplace"
          rows="1"
          value={waze}
          maxLength="49"
          onChange={(e) => setWaze(e.target.value)}
        ></textarea>

        <div className="section-title">
          תיאור חופשי {freeTextLength + "/400 "}
        </div>
        <textarea
          className="titleplace"
          rows="6"
          value={freetext}
          maxLength="400"
          onChange={(e) => handleText(e.target.value)}
        ></textarea>

        <br />
        {isErrorMessageDisplayed && (
          <div className="error-message">
            <h5>{errorMessage} </h5>
          </div>
        )}

        <div
          className="button-text-center"
          id="btnSubmit"
          onClick={onSubmitAddPlace}
        >
          שמירת המקום
        </div>
        <br />

        <Link to={"/questionnaires"} style={{ textDecoration: "none" }}>
          <div className="button whiteBackNBorder" id="backBtn">
            {" "}
            <b>→</b>
            חזרה
          </div>
        </Link>
      </form>{" "}
    </div>
  );
};

export default AddPlace;
