import React from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import "./Article.css";
import axios from "axios";
import HeaderBackToBlog from "../../components/Header/HeaderBackToBlog.js";
import PhotoArticle from "../../components/PhotoArticle/PhotoArticle";
import FirstDatesInterestingEgoza from "../../Articles/firstDatesInterestingEgoza.js";
import QualityOfDateShirliVal from "../../Articles/qualityOfDateShirliVal.js";
import JobOrFunSaritShavit from "../../Articles/jobOrFunSaritShavit.js";
import CreateMeDateOsifGonen from "../../Articles/createMeDateOsifGonen.js";
import MistakeInDateShirliVal from "../../Articles/mistakeInDateShirliVal";
import BelieveInYourselfSariel from "../../Articles/believeInYourselfSariel";
import SecretSuccessEliGaron from "../../Articles/secretSuccessEliGaron";

const Article = () => {
  const history = useHistory();
  const photoImportanceClick = () => {
    if (document.getElementById("photoImportance").style.display === "block") {
      document.getElementById("photoImportance").style.display = "none";
    } else {
      document.getElementById("photoImportance").style.display = "block";
    }
  };

  const tipWritersCreateMeDate = [
    {
      article: <CreateMeDateOsifGonen />,
      title: "  הכותב: אוסיף גונן ",
      subTitle: " מאמן גברים ",
      subTitle2: " ליצירת זוגיות",
      phoneNumber: "542384995",
      photo: "OsifGonen",
    },
  ];

  const tipWritersBelieveInYourself = [
    {
      article: <BelieveInYourselfSariel />,
      title: "  הכותב: שריאל אהרונוביץ",
      subTitle: "מכוון אנשים בני 20-35",
      subTitle2: " ליציאה מסטרס, הגשמה ושמחת חיים.",
      phoneNumber: "525398533",
      photo: "Sariel",
    },
  ];

  const tipWritersFirstDates = [
    {
      article: <FirstDatesInterestingEgoza />,
      title: '  הכותבת: ד"ר אגוזה וסרמן',
      subTitle: " מאמנת למערכות יחסים,",
      subTitle2: " מציאת זוגיות וחתונה.",
      phoneNumber: "505377525",
      photo: "EgozaW",
    },
    {
      article: <JobOrFunSaritShavit />,
      title: "  הכותבת: שרית שביט",
      subTitle: " מאמנת רווקים ורווקות",
      subTitle2: " להגיע לזוגיות באושר",
      phoneNumber: "506596984",
      photo: "SaritShavit",
    },
    {
      article: <MistakeInDateShirliVal />,
      title: "  הכותבת: שירלי והל",
      subTitle: "מבלתי אפשרי לאפשרי",
      subTitle2: " אימון לזוגיות",
      phoneNumber: "545757469",
      photo: "ShirliVal",
    },
  ];

  const tipWritersQualityOfDates = [
    {
      article: <QualityOfDateShirliVal />,
      title: "  הכותבת: שירלי והל",
      subTitle: "מבלתי אפשרי לאפשרי",
      subTitle2: " אימון לזוגיות",
      phoneNumber: "545757469",
      photo: "ShirliVal",
    },
    {
      article: <SecretSuccessEliGaron />,
      title: "  הכותב: אלי גרון",
      subTitle: "בית ספר לזוגיות",
      subTitle2: " בואי כלה",
      phoneNumber: "0",
      photo: "EliGaron",
    },
  ];

  const firstDatesInterestingEgozaClick = () => {
    if (
      document.getElementById("firstDatesInterestingEgoza").style.display ===
      "block"
    ) {
      document.getElementById("firstDatesInterestingEgoza").style.display =
        "none";
    } else {
      document.getElementById("firstDatesInterestingEgoza").style.display =
        "block";
    }
  };

  const qualityOfDateShirliValClick = () => {
    if (
      document.getElementById("qualityOfDateShirliVal").style.display ===
      "block"
    ) {
      document.getElementById("qualityOfDateShirliVal").style.display = "none";
    } else {
      document.getElementById("qualityOfDateShirliVal").style.display = "block";
    }
  };

  const createMeDateClick = () => {
    if (document.getElementById("createMeDate").style.display === "block") {
      document.getElementById("createMeDate").style.display = "none";
    } else {
      document.getElementById("createMeDate").style.display = "block";
    }
  };

  const believeInYourselfClick = () => {
    if (
      document.getElementById("believeInYourself").style.display === "block"
    ) {
      document.getElementById("believeInYourself").style.display = "none";
    } else {
      document.getElementById("believeInYourself").style.display = "block";
    }
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const tryToBid = async (cell) => {
    console.log("test - post");
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PtryToBid",
        { cell },
        config
      );
    } catch (error) {}
  };

  return (
    <form className="forum-main-form">
      <HeaderBackToBlog />

      <br></br>

      <div className="form-body">
        <div className="choose_room">
          <h1 className="h1-center">טיפים קלילים </h1>
          <div className="collabbtn" onClick={photoImportanceClick}>
            יו איזה תמונה יפה! משנה בכלל?
          </div>
          <div id="photoImportance" className="displayNone">
            <PhotoArticle />
          </div>

          <div className="collabbtn" onClick={createMeDateClick}>
            לייצר לעצמי דייטים
          </div>
          <div id="createMeDate" className="tipArea">
            {/* <div className="smallArticle"> */}

            {tipWritersCreateMeDate.map((currentTip) => {
              return (
                <div key={currentTip.article} className="tip1">
                  <br />
                  <div> {currentTip.article} </div>
                  <div style={{ height: "150px" }}>
                    <div className="photographline">
                      <div className="rightCol">
                        <div> {currentTip.title} </div>
                        <div> {currentTip.subTitle} </div>
                        <div> {currentTip.subTitle2} </div>
                        <div> {"ליצירת קשר:  0" + currentTip.phoneNumber} </div>
                      </div>
                      <div className="leftCol">
                        <div className={currentTip.photo} />

                        <div
                          className="whatsupFloata"
                          onClick={() => tryToBid(currentTip.phoneNumber)}
                        >
                          <a
                            href={`https://wa.me/972${currentTip.phoneNumber}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div className="whatsupTips" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* </div> */}
          </div>
          <div className="collabbtn" onClick={believeInYourselfClick}>
            להאמין בעצמי
          </div>
          <div id="believeInYourself" className="tipArea">
            {/* <div className="smallArticle"> */}

            {tipWritersBelieveInYourself.map((currentTip) => {
              return (
                <div key={currentTip.article} className="tip1">
                  <br />
                  <div> {currentTip.article} </div>
                  <div style={{ height: "150px" }}>
                    <div className="photographline">
                      <div className="rightCol">
                        <div> {currentTip.title} </div>
                        <div> {currentTip.subTitle} </div>
                        <div> {currentTip.subTitle2} </div>
                        <div> {"ליצירת קשר:  0" + currentTip.phoneNumber} </div>
                      </div>
                      <div className="leftCol">
                        <div className={currentTip.photo} />

                        <div
                          className="whatsupFloata"
                          onClick={() => tryToBid(currentTip.phoneNumber)}
                        >
                          <a
                            href={`https://wa.me/972${currentTip.phoneNumber}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div className="whatsupTips" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* </div> */}
          </div>
          <div className="collabbtn" onClick={firstDatesInterestingEgozaClick}>
            דייטים ראשונים
          </div>
          <div id="firstDatesInterestingEgoza" className="tipArea">
            {/* <div className="smallArticle"> */}
            {/* <FirstDatesInterestingEgoza /> */}
            {tipWritersFirstDates.map((currentTip) => {
              return (
                <div key={currentTip.article} className="tip1">
                  <br />
                  <div> {currentTip.article} </div>
                  <div style={{ height: "150px" }}>
                    <div className="photographline">
                      <br />
                      <div className="rightCol">
                        <div> {currentTip.title} </div>
                        <div> {currentTip.subTitle} </div>
                        <div> {currentTip.subTitle2} </div>
                        <div> {"ליצירת קשר:  0" + currentTip.phoneNumber} </div>
                      </div>
                      <div className="leftCol">
                        <div className={currentTip.photo} />

                        <div
                          className="whatsupFloata"
                          onClick={() => tryToBid(currentTip.phoneNumber)}
                        >
                          <a
                            href={`https://wa.me/972${currentTip.phoneNumber}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div className="whatsupTips" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* </div> */}
          </div>
          <div className="collabbtn" onClick={qualityOfDateShirliValClick}>
            איכות הקשר
          </div>
          <div id="qualityOfDateShirliVal" className="tipArea">
            {/* <div className="smallArticle"> */}

            {tipWritersQualityOfDates.map((currentTip) => {
              return (
                <div key={currentTip.article} className="tip1">
                  <br />
                  <div> {currentTip.article} </div>
                  <div style={{ height: "150px" }}>
                    <div className="photographline">
                      <div className="rightCol">
                        <div> {currentTip.title} </div>
                        <div> {currentTip.subTitle} </div>
                        <div> {currentTip.subTitle2} </div>
                        <div> {"ליצירת קשר:  0" + currentTip.phoneNumber} </div>
                      </div>
                      <div className="leftCol">
                        <div className={currentTip.photo} />

                        <div
                          className="whatsupFloata"
                          onClick={() => tryToBid(currentTip.phoneNumber)}
                        >
                          <a
                            href={
                              currentTip.phoneNumber == "0"
                                ? "https://zoogyot.co.il/%D7%9E%D7%93%D7%A8%D7%99%D7%9A-%D7%97%D7%99%D7%A0%D7%9E%D7%99-%D7%93%D7%A3-%D7%A0%D7%97%D7%99%D7%AA%D7%94/"
                                : `https://wa.me/972${currentTip.phoneNumber}`
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div
                              className={
                                currentTip.phoneNumber == "0"
                                  ? "lbl"
                                  : "whatsupTips"
                              }
                            />
                            {currentTip.phoneNumber == "0"
                              ? " קישור לאתר של אלי"
                              : ""}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* </div> */}
          </div>
        </div>

        <br></br>
        <br></br>

        <Link to="/blog" style={{ textDecoration: "none" }}>
          <div className="button whiteBackNBorder " id="backBtn">
            {" "}
            חזרה
            <b>→</b>
          </div>
        </Link>
      </div>
    </form>
  );
};

export default Article;
