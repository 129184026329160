import React from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import "./Terms.css";
import TermsAndConditions from "../../components/TermsAndConditions/TermsAndConditions";
import HeaderBack from "../../components/Header/HeaderBack";

const Terms = () => {
  let history = useHistory();
  return (
    <div className="aboutdouble-full-screen">
      <HeaderBack />
      <br />
      <form className="aboutdouble-form">
        <h1 className="h1-homepage">תקנון דאבל הכרויות</h1>
        <TermsAndConditions />
        <Link to={"/"} style={{ textDecoration: "none" }}>
          <div className="button whiteBackNBorder" id="backBtn">
            {" "}
            <b>→</b>
            חזרה
          </div>
        </Link>
      </form>{" "}
    </div>
  );
};

export default Terms;
