import React from "react";

const FacebookHelper = () => {
  return (
    // <div className="terms-and-conditions-container">
    // {/* className="terms-and-conditions-p" */}
    <div className="terms-and-conditions-p">
      כניסה לפרופיל של עצמך בפייס, לחיצה על … עוד, בחירת: ’העתק את הקישור
      לפרופיל‘
      <br />
      <br />
      <a
        href="https://m.facebook.com/profile.php"
        target="_blank"
        rel="noopener noreferrer"
      >
        {" "}
        ואפשר ללחוץ כאן ולקבל לינק לפייס
      </a>
    </div>
    // </div>
  );
};

export default FacebookHelper;
