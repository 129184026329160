import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import "../../App.css";
import "./AboutMe.css";
import "../../slider.css";
import HeaderBackToEdit from "../../components/Header/HeaderBackToEdit";
// import { Slider } from "rsuite";
import axios from "axios";
import CitiesSearch from "../../components/CitiesSearch/CitiesSearch";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import FacebookHelper from "../../components/FacebookHelper/FacebookHelper";
import InstegramHelper from "../../components/InstegramHelper/InstegramHelper";
import {
  getWordByGender,
  getHobby,
  getHared,
} from "../../handlers/dictionaryHandler.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AboutMeHared from "../../components/AboutMeHared/AboutMeHared";
import cubesvideo from "../../videos/cubesvideo.mp4";
import Cookies from "js-cookie";
import DiceLoading from "../../components/DiceLoading.js";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const SliderWithTooltip = createSliderWithTooltip(Slider);

const defaultcharacter = [
  {
    type: "serious",
    textright: "בזרימה עם החיים",
    textleft: getWordByGender("serious"),
  },
  {
    type: "simpliest",
    textright: "השיחות איתי פשוטות",
    textleft: "אני נוטה להתפלסף",
  },
  {
    type: "activity",
    textright: "נוטה לפעילות ואקטיביות",
    textleft: "תנו לי את השקט שלי",
  },
  {
    type: "popular",
    textright: "מסמר הערב",
    textleft: "נחבא אל הכלים",
  },
  {
    type: "bourgeoise",
    textright: "סגנון החיים שלי בסטייל",
    textleft: "סגנון החיים שלי פשוט",
  },
  { type: "friendly", textright: "סחבק", textleft: "שומר 2 מטר" },
];

const format = (time) => {
  // Hours, minutes and seconds
  var hrs = ~~(time / 3600);
  var mins = ~~((time % 3600) / 60);
  var secs = ~~time % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
};

const dataURItoBlobAndBuffer = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return [blob, ab];
};

const AboutMe = () => {
  let history = useHistory();
  const myRef = useRef(null);
  const [viewIndex, setViewIndex] = useState(
    // &&&&&&&
    // localStorage.getItem("fromMiror") == 1 && Cookies.get("newUser") == 1
    //   ? 4 :
    1
  );
  const [birthday, setBirthday] = useState("");
  const [isCityValid, setIsCityValid] = useState(false); // means if the chosen city is from the list from DB
  const [city, setCity] = useState("");
  const [facebook, setFacebook] = useState("");
  const [image1, setImage1] = useState("");
  const [additionalImages, setAdditionalImages] = useState([]);

  const [image1BlobURL, setImage1BlobURL] = useState("");
  const [isImage1CheckLoading, setIsImage1CheckLoading] = useState(false);
  const [isImage1Valid, setIsImage1Valid] = useState(false);

  const [instegram, setInstegram] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [myDat, setMyDat] = useState("");
  const [tall, setTall] = useState("");
  const [myStatus, setMyStatus] = useState("");
  const [myEdu, setMyEdu] = useState("");
  const [freetext, setFreetext] = useState("");
  const [freeTextLength, setFreeTextLength] = useState("");
  const [myHobby, setMyHobby] = useState([]);
  const [hobbieFree, setHobbieFree] = useState("");
  const [occupation, setOccupation] = useState("");
  const [character, setCharacter] = useState(defaultcharacter);
  const [isDataLoaded, setIsDataLoaded] = useState();

  const handleMyHobby = (val) => {
    if (!myHobby.includes(val)) {
      setMyHobby([...myHobby, val]);
    } else {
      const tempHobby = myHobby.filter((value) => value != val);
      setMyHobby(tempHobby);
    }
  };

  const [isFacebookHelperDisplayed, setIsFacebookHelperDisplayed] =
    useState(false);
  const [isInstegramHelperDisplayed, setIsInstegramHelperDisplayed] =
    useState(false);

  const [isFreetextHelperDisplayed, setIsFreetextHelperDisplayed] =
    useState(false);
  const [isPhotoHelperDisplayed, setIsPhotoHelperDisplayed] = useState(false);
  const [isRecordHelperDisplayed, setIsRecordHelperDisplayed] = useState(false);

  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  // this number is needed for validation
  const numberOfCharectersParameters = 6;
  const [charcterDictionary, setCharcterDictionary] = useState({});
  const maxAgeAllowed = 70;
  const minAgeAllowed = 18;

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_LOCALHOST_ADDRESS + "/PaboutMe",
          config
        );
        const userData = response.data;
        if (userData.city.length > 0) {
          setCity(userData.city);
          if (userData.city !== "") {
            setIsCityValid(true);
          }
          // setEmail(userData.email);

          const birthYear = userData.birthday.toString().substring(0, 4);
          const birthMonth = userData.birthday.toString().substring(4, 6);
          const birthDay = userData.birthday.toString().substring(6, 8);
          const birthDate = `${birthYear}-${birthMonth}-${birthDay}`;
          setBirthday(birthDate);
          setFacebook(userData.faceBook);

          setInstegram(userData.instegram);
          setLinkedin(userData.linkedin);
        }
        if (
          userData.occupation.length > 0 ||
          userData.freeText.length > 0 ||
          userData.origin.length > 0 ||
          userData.rec1.length > 0 ||
          userData.cellFriend.length > 0
        ) {
          setOccupation(userData.occupation);

          // To load the record
        }

        if (userData.serious.toString() > 0) {
          setMyDat(userData.religous.toString());

          setMyEdu(userData.education);
          setTall(userData.tall);

          // setFreetext(userData.freeText);
          setMyStatus(userData.status.toString());
          setMyHobby(userData.hobbies.split(","));
          setHobbieFree(userData.hobbieFree);

          setCharcterDictionary({
            serious: userData.serious.toString(),
            simpliest: userData.simpliest.toString(),
            activity: userData.activity.toString(),
            popular: userData.popular.toString(),
            bourgeoise: userData.bourgeoise.toString(),
            friendly: userData.friendly.toString(),
          });

          setIsDataLoaded(true);
        } else {
          setIsDataLoaded(true); // in case its a new user
        }
      } catch (error) {
        // probebly user is not authenticated or server is down
      }
    };

    loadData();
  }, []);

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const formatDateToDate = (formatDate) => {
    if (formatDate.length > 0)
      return new Date(
        formatDate.split("-")[0],
        formatDate.split("-")[1] - 1,
        formatDate.split("-")[2]
      );
  };

  const urlValidator = (url) => {
    const urlRexge =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const regex = new RegExp(urlRexge);

    return url.match(regex);
  };

  const alertUser = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessage(message);
  };

  const handleMyDat = (val) => {
    setMyDat(val);
  };
  const handleMyStatus = (val) => {
    setMyStatus(val);
  };

  const handleMyEdu = (val) => {
    setMyEdu(val);
  };

  const handleCharacter = (char, e) => {
    setCharcterDictionary({
      ...charcterDictionary,
      [char.type]: e.target.value,
    });
  };

  const validateBirthdate = (date) => {
    if (date) {
      const birthdateYear = date.split("-")[0];
      const currentYear = new Date().getYear() + 1900;
      if (
        currentYear - birthdateYear >= minAgeAllowed &&
        currentYear - birthdateYear <= maxAgeAllowed
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const facebookLinkValidator = (link) => {
    return urlValidator(link) && link.includes("facebook.com/");
  };

  // const resizeImageFn = (file) => {
  //   const resizedImage = compress.compress([file], {
  //     size: 2, // the max size in MB, defaults to 2MB
  //     quality: 1, // the quality of the image, max is 1,
  //     maxWidth: 300, // the max width of the output image, defaults to 1920px
  //     maxHeight: 300, // the max height of the output image, defaults to 1920px
  //     resize: true, // defaults to true, set false if you do not want to resize the image width and height
  //   });
  //   const img = resizedImage[0];
  //   const base64str = img.data;
  //   const imgExt = img.ext;
  //   const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt);
  //   return resizedFiile;
  // };

  const getClassNameForCharacterCube = (currentValue, myValue) => {
    let defaultClassName = "characterCube";

    if (currentValue == myValue) {
      defaultClassName += " active";
    } else if (currentValue == myValue - 1) {
      // Right
      defaultClassName += " rightCharacterCube";
    } else if (currentValue == myValue + 1) {
      // Left
      defaultClassName += " leftCharacterCube";
    }

    return defaultClassName;
  };

  const onSubmitStep1 = async () => {
    if (validateBirthdate(birthday)) {
      if (isCityValid) {
        if (
          (instegram.length > 1 && urlValidator(instegram)) ||
          instegram.trim().length == 0
        ) {
          if (
            (linkedin.length > 1 && urlValidator(linkedin)) ||
            linkedin.trim().length == 0
          ) {
            if (!facebook.includes("m.facebook.com/profile.php")) {
              //if (!facebook.includes(" ") && facebook.length > 1) {
              if (
                (facebookLinkValidator(facebook) &&
                  facebook.trim().length > 1) ||
                facebook.trim().length == 0
              ) {
                try {
                  const response = await axios.post(
                    process.env.REACT_APP_LOCALHOST_ADDRESS + "/PaboutMeStep1",
                    {
                      birthday:
                        birthday.substring(0, 4) +
                        birthday.substring(5, 7) +
                        birthday.substring(8, 10),
                      city,
                      facebook: facebook.trim(),
                      instegram,
                      linkedin,
                    },
                    config
                  );

                  if (response.data === true) {
                    setViewIndex(2);
                    myRef.current.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });

                    alertUser("");
                  } else {
                    alertUser(
                      "יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית"
                    );
                  }
                } catch (error) {
                  console.log(error);
                  alertUser(
                    "יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית"
                  );
                }
              } else {
                alertUser(
                  "הלינק לפייסבוק לא תקין, הוראות לפייס תקין מופיעות למעלה"
                );
                console.log("Facebook url is not valid");
              }
            } else {
              alertUser("הלינק לפייסבוק לא כולל את השם שלך, מצב בעייתי");
              console.log("Facebook url is not valid");
            }
          } else {
            alertUser("הלינק ללינקדין ארוך מידי או לא תקין");
            console.log("linkedin too long url is not valid");
          }
        } else {
          alertUser("הלינק לאינסטגרם ארוך מידי או לא תקין");
          console.log("instegram too long url is not valid");
        }
      } else {
        alertUser("העיר שנבחרה לא מהרשימה");
        console.log("City is not valid");
      }
    } else {
      alertUser("תאריך לידה חסר או לא בטווח התקין");
      console.log("birthday is not in valid age");
    }
  };

  const onSubmitStep2 = async () => {
    if (myDat != "") {
      if (tall != "" && tall != 100) {
        if (myStatus != "") {
          if (myEdu != "") {
            if (hobbieFree.length < 40) {
              if (occupation.length < 40) {
                if (myHobby != "" && myHobby != "0") {
                  if (
                    Object.keys(charcterDictionary).length ==
                    numberOfCharectersParameters
                  ) {
                    try {
                      const response = await axios.post(
                        process.env.REACT_APP_LOCALHOST_ADDRESS +
                          "/PaboutMeStep2",
                        {
                          myDat,
                          tall,
                          myStatus,
                          myEdu,
                          serious: charcterDictionary.serious,
                          simpliest: charcterDictionary.simpliest,
                          activity: charcterDictionary.activity,
                          popular: charcterDictionary.popular,
                          bourgeoise: charcterDictionary.bourgeoise,
                          friendly: charcterDictionary.friendly,
                          myHobby,
                          hobbieFree,
                          occupation,
                        },
                        config
                      );
                      if (response.data === true) {
                        if (
                          getHared() === 1 ||
                          myDat === "3" ||
                          myDat === "9"
                        ) {
                          setViewIndex(3);
                          myRef.current.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        } else {
                          history.push("/aboutpartner");
                        }

                        alertUser("");
                      } else {
                        alertUser(
                          "יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית"
                        );
                      }
                    } catch (error) {
                      console.log(error);
                      alertUser(
                        "יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית"
                      );
                    }
                  } else {
                    alertUser("לא כל מדדי האופי מולאו");
                    console.log("Not all personal data is filled");
                  }

                  // console.log(response.data[0][0]);
                } else {
                  alertUser("לא בחרת תחביב");
                  console.log("Hobby did not set");
                }
              } else {
                alertUser("עיסוק ארוך מידי");
                console.log("occupation too long");
              }
            } else {
              alertUser("תחביב נוסף ארוך מידי");
              console.log("Education did not set");
            }
          } else {
            alertUser("לא בחרת השכלה");
            console.log("Education did not set");
          }
        } else {
          alertUser("לא בחרת סטטוס זוגי");
          console.log("status not set");
        }
      } else {
        alertUser("לא בחרת גובה ");
        console.log("tall did not set");
      }
    } else {
      alertUser("לא בחרת סגנון דתי");
      console.log("Dat did not chosen");
    }
  };

  const rendercharacterButtons = () => {
    const characterButtons = character.map((character) => (
      <div key={character.type}>
        <br />
        <div id="hright">{character.textright}</div>
        <div id="hleft">{character.textleft}</div>
        <br></br>
        <div className="parent-7cubes">
          <input
            type="button"
            // id={character.type + "1"}
            value="1"
            // className="characterCube"
            id={character.type}
            className={getClassNameForCharacterCube(
              charcterDictionary[character.type],
              1
            )}
            onClick={(e) => handleCharacter(character, e)}
          />
          <input
            type="button"
            id={character.type}
            value="2"
            className={getClassNameForCharacterCube(
              charcterDictionary[character.type],
              2
            )}
            onClick={(e) => handleCharacter(character, e)}
          />
          <input
            type="button"
            id={character.type}
            value="3"
            className={getClassNameForCharacterCube(
              charcterDictionary[character.type],
              3
            )}
            onClick={(e) => handleCharacter(character, e)}
          />
          <input
            type="button"
            id={character.type}
            value="4"
            className={getClassNameForCharacterCube(
              charcterDictionary[character.type],
              4
            )}
            onClick={(e) => handleCharacter(character, e)}
          />
          <input
            type="button"
            id={character.type}
            value="5"
            className={getClassNameForCharacterCube(
              charcterDictionary[character.type],
              5
            )}
            onClick={(e) => handleCharacter(character, e)}
          />
          <input
            type="button"
            id={character.type}
            value="6"
            className={getClassNameForCharacterCube(
              charcterDictionary[character.type],
              6
            )}
            onClick={(e) => handleCharacter(character, e)}
          />
          <input
            type="button"
            id={character.type}
            value="7"
            className={getClassNameForCharacterCube(
              charcterDictionary[character.type],
              7
            )}
            onClick={(e) => handleCharacter(character, e)}
          />
        </div>
      </div>
    ));

    return <div className="questionnaires-buttons">{characterButtons}</div>;
  };
  return (
    <form id="formId" ref={myRef}>
      <HeaderBackToEdit />

      {isDataLoaded === true ? (
        <>
          <div className="steps">
            <div
              className={
                viewIndex > 0 ? "step-about-me-after" : "step-about-me"
              }
              onClick={() => setViewIndex(1)}
            >
              1
            </div>
            <div
              className={
                viewIndex > 1 ? "line-between-step-after" : "line-between-step"
              }
            ></div>
            <div
              className={
                viewIndex > 1 ? "step-about-me-after" : "step-about-me"
              }
              onClick={() => setViewIndex(2)}
            >
              2
            </div>
            {getHared() === 1 || myDat === "3" || myDat === "9" ? (
              <div
                className={
                  viewIndex > 2
                    ? "line-between-step-after"
                    : "line-between-step"
                }
              ></div>
            ) : (
              <></>
            )}
            {getHared() === 1 || myDat === "3" || myDat === "9" ? (
              <div
                className={
                  viewIndex > 2 ? "step-about-me-after" : "step-about-me"
                }
                onClick={() => setViewIndex(3)}
              >
                3
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="title"> </div>
          <div className="page-title">
            <div>אז... ספרו לי קצת על עצמכם</div>
          </div>

          <div className="aboutme-body">
            {viewIndex === 1 && (
              <>
                <div
                  className={
                    Cookies.get("newUser") == 1 ? "smallArticle" : "invisible"
                  }
                >
                  רגע לפני שנתחיל, ניזכר במשפט מהחכמים:
                  <br />
                  <b>"לא עליך המלאכה לגמור, ואין אתה בן חורין להיבטל ממנה".</b>
                  <br />
                  הכוונה שחייבים לנסות ולהתאמץ, אבל לא צריך "להתאבד" על משהו, לא
                  כל האחריות היא שלנו.
                  <br />
                  אז כל הכבוד על ההרשמה, זה לא תמיד קל. מה שבטוח - כאן בדאבל זה
                  יהיה באופן מכבד, לא "ידפדפו" אותך. רק ההתאמה שלך תראה את
                  הפרטים שלך. ממש כמו שחבר מכיר לך כשיש לו רעיון לפי מה שתיארת
                  לו.
                  <br />
                  מתחילים :)
                </div>
                <br />
                <div className="section-title">
                  <b>תאריך לידה </b>
                  dd/mm/yyyy{" "}
                </div>

                <DatePicker
                  selected={formatDateToDate(birthday)}
                  onChange={(date) => setBirthday(formatDate(date))}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/yyyy"
                  className="birthday"
                />

                <CitiesSearch
                  onSelect={(city, isValidCity) => {
                    setIsCityValid(isValidCity);
                    if (isValidCity) {
                      setCity(city);
                    }
                  }}
                  startValue={city}
                  key={city}
                />

                <div className="line" />
                <div className="section-title"></div>

                <div className="faceline">
                  <input
                    type="text"
                    id="facebook"
                    placeholder="קישור לפייסבוק שלך"
                    value={facebook}
                    onChange={(e) => setFacebook(e.target.value)}
                  ></input>

                  <div
                    className="helphand shadow"
                    onClick={() => setIsFacebookHelperDisplayed(true)}
                  />
                </div>

                <Popup
                  open={isFacebookHelperDisplayed}
                  contentStyle={{
                    minWidth: "30%",
                    maxWidth: "60%",
                    borderRadius: "5px",
                  }}
                  position="right center"
                  onClose={() => setIsFacebookHelperDisplayed(false)}
                  closeOnDocumentClick
                  closeOnEscape
                >
                  {FacebookHelper}
                </Popup>

                <br />
                <div className="faceline">
                  <input
                    type="text"
                    id="instegram"
                    placeholder="קישור לאינסטגרם שלך"
                    value={instegram}
                    maxLength="99"
                    onChange={(e) => setInstegram(e.target.value)}
                  ></input>
                  <div
                    className="helphand shadow"
                    onClick={() => setIsInstegramHelperDisplayed(true)}
                  />
                </div>

                <Popup
                  open={isInstegramHelperDisplayed}
                  contentStyle={{
                    minWidth: "30%",
                    maxWidth: "60%",
                    borderRadius: "5px",
                  }}
                  position="right center"
                  onClose={() => setIsInstegramHelperDisplayed(false)}
                  closeOnDocumentClick
                  closeOnEscape
                >
                  {InstegramHelper}
                </Popup>

                <br />
                <div className="faceline">
                  <input
                    type="text"
                    id="linkedin"
                    placeholder="קישור ללינקדין שלך"
                    value={linkedin}
                    maxLength="99"
                    onChange={(e) => setLinkedin(e.target.value)}
                  ></input>
                </div>

                {/* <div className="line" /> */}
              </>
            )}

            {viewIndex === 2 && (
              <>
                <div className="parent-dat">
                  <div className="section-title">
                    <b>דת</b>
                  </div>
                  <input
                    type="button"
                    name="dat"
                    className={`dati ${myDat === "1" ? "active" : ""}`}
                    value={getWordByGender("mdati")}
                    key="1"
                    onClick={(e) => handleMyDat("1")}
                  />
                  <input
                    type="button"
                    name="dat"
                    className={`datito ${myDat === "2" ? "active" : ""}`}
                    value={getWordByGender("mdatito")}
                    key="2"
                    onClick={(e) => handleMyDat("2")}
                  />
                  <input
                    type="button"
                    name="dat"
                    className={`modern ${myDat === "3" ? "active" : ""}`}
                    value={getWordByGender("mmodern")}
                    key="3"
                    onClick={(e) => handleMyDat("3")}
                  />
                  <input
                    type="button"
                    name="dat"
                    className={`hardal ${myDat === "4" ? "active" : ""}`}
                    // style={{
                    //   top: "7px",
                    // }}
                    value={getWordByGender("mhardal")}
                    key="4"
                    onClick={(e) => handleMyDat("4")}
                  />
                  <input
                    type="button"
                    name="dat"
                    className={`light ${myDat === "5" ? "active" : ""}`}
                    value={getWordByGender("mlight")}
                    key="5"
                    onClick={(e) => handleMyDat("5")}
                  />
                  <input
                    type="button"
                    name="dat"
                    className={`trad ${myDat === "6" ? "active" : ""}`}
                    value={getWordByGender("mtrad")}
                    key="6"
                    onClick={(e) => handleMyDat("6")}
                  />
                  <input
                    type="button"
                    name="dat"
                    className={`datil ${myDat === "7" ? "active" : ""}`}
                    value={getWordByGender("mdatil")}
                    key="7"
                    onClick={(e) => handleMyDat("7")}
                  />
                  <input
                    type="button"
                    name="dat"
                    className={`datlash ${myDat === "8" ? "active" : ""}`}
                    value={getWordByGender("mdatlash")}
                    key="8"
                    onClick={(e) => handleMyDat("8")}
                  />
                  <input
                    type="button"
                    name="dat"
                    className={`hared ${myDat === "9" ? "active" : ""}`}
                    value={getWordByGender("mhared")}
                    key="9"
                    onClick={(e) => handleMyDat("9")}
                  />
                  <input
                    type="button"
                    name="dat"
                    className={`hozer ${myDat === "10" ? "active" : ""}`}
                    value={getWordByGender("mhozer")}
                    key="10"
                    onClick={(e) => handleMyDat("10")}
                  />
                  <input
                    type="button"
                    name="dat"
                    className={`hilon ${myDat === "11" ? "active" : ""}`}
                    value={getWordByGender("mhilon")}
                    key="11"
                    onClick={(e) => handleMyDat("11")}
                  />
                </div>

                <div className="line" />
                {/* {["2", "3", "4", "9", "10"].indexOf(myDat) >= 0 &&
                getHared() == "1" ? (
                  <AboutMeHared />
                ) : (
                  ""
                )} */}

                <div className="section-title">
                  <b>גובה</b>
                </div>

                <SliderWithTooltip
                  progress
                  min={100}
                  max={200}
                  value={tall}
                  style={{ marginTop: 36 }}
                  defaultValue={150}
                  tipProps={{ visible: true }}
                  onChange={(e) => setTall(e)}
                />
                <br />
                <div className="line" />
                <div className="parent-status">
                  <input
                    type="button"
                    name="status"
                    className={`single ${myStatus === "1" ? "active" : ""}`}
                    value={getWordByGender("msingle")}
                    key="1"
                    onClick={(e) => handleMyStatus("1")}
                  />
                  <input
                    type="button"
                    name="status"
                    className={`divo ${myStatus === "2" ? "active" : ""}`}
                    value={getWordByGender("mdivo")}
                    key="2"
                    onClick={(e) => handleMyStatus("2")}
                  />
                  <input
                    type="button"
                    name="status"
                    className={`divop ${myStatus === "3" ? "active" : ""}`}
                    value={getWordByGender("mdivop")}
                    key="3"
                    onClick={(e) => handleMyStatus("3")}
                  />
                  <input
                    type="button"
                    name="status"
                    className={`widow ${myStatus === "4" ? "active" : ""}`}
                    value={getWordByGender("mwidow")}
                    key="4"
                    onClick={(e) => handleMyStatus("4")}
                  />
                  <input
                    type="button"
                    name="status"
                    className={`singleplus ${myStatus === "5" ? "active" : ""}`}
                    value={getWordByGender("msingleplus")}
                    key="5"
                    onClick={(e) => handleMyStatus("5")}
                  />
                </div>
                <div className="line" />
                <div className="section-title">
                  <b>השכלה</b>
                </div>
                <div className="parent-edu">
                  <input
                    type="button"
                    name="edu"
                    className={`highschool ${myEdu === "1" ? "active" : ""}`}
                    value="תיכונית"
                    key="1"
                    onClick={(e) => handleMyEdu("1")}
                  />
                  <input
                    type="button"
                    name="edu"
                    className={`student ${myEdu === "2" ? "active" : ""}`}
                    value={getWordByGender("mstudent")}
                    key="2"
                    onClick={(e) => handleMyEdu("2")}
                  />
                  <input
                    type="button"
                    name="edu"
                    className={`academy ${myEdu === "3" ? "active" : ""}`}
                    value="אקדמית"
                    key="3"
                    onClick={(e) => handleMyEdu("3")}
                  />
                  <input
                    type="button"
                    name="edu"
                    className={`other ${myEdu === "4" ? "active" : ""}`}
                    value={"קבע/ישיבה/נדלן /8200 וכד'"}
                    key="4"
                    onClick={(e) => handleMyEdu("4")}
                  />
                  {/* <div className="section-title">
                    {" "}
                    'אחר: קבע, נדלן, 8200, ישיבה וכד*
                  </div> */}
                </div>
                <div className="faceLine">
                  <br />
                  <div className="section-title">
                    <b>עיסוק</b>
                  </div>
                  <textarea
                    className="occupation"
                    rows="1"
                    value={occupation}
                    onChange={(e) => setOccupation(e.target.value)}
                  ></textarea>
                </div>

                <div className="line" />

                <div className="section-title">
                  <b>תחביבים</b>
                </div>
                <div className="parent-edu">
                  <input
                    type="button"
                    className={`hobby ${myHobby.includes("1") ? "active" : ""}`}
                    value={getHobby("1")}
                    onClick={(e) => handleMyHobby("1")}
                  />
                  <input
                    type="button"
                    className={`hobby ${myHobby.includes("2") ? "active" : ""}`}
                    value={getHobby("2")}
                    onClick={(e) => handleMyHobby("2")}
                  />
                  <input
                    type="button"
                    className={`hobby ${myHobby.includes("3") ? "active" : ""}`}
                    value={getHobby("3")}
                    onClick={(e) => handleMyHobby("3")}
                  />
                  <input
                    type="button"
                    className={`hobby ${myHobby.includes("4") ? "active" : ""}`}
                    value={getHobby("4")}
                    onClick={(e) => handleMyHobby("4")}
                  />
                </div>

                <div className="parent-edu">
                  <input
                    type="button"
                    className={`hobby ${myHobby.includes("5") ? "active" : ""}`}
                    value={getHobby("5")}
                    onClick={(e) => handleMyHobby("5")}
                  />
                  <input
                    type="button"
                    className={`hobby ${myHobby.includes("6") ? "active" : ""}`}
                    value={getHobby("6")}
                    onClick={(e) => handleMyHobby("6")}
                  />
                  <input
                    type="button"
                    className={`hobby ${myHobby.includes("7") ? "active" : ""}`}
                    value={getHobby("7")}
                    onClick={(e) => handleMyHobby("7")}
                  />
                  <input
                    type="button"
                    className={`hobby ${myHobby.includes("8") ? "active" : ""}`}
                    value={getHobby("8")}
                    onClick={(e) => handleMyHobby("8")}
                  />
                </div>

                <div className="parent-edu">
                  <input
                    type="button"
                    className={`hobby ${myHobby.includes("9") ? "active" : ""}`}
                    value={getHobby("9")}
                    onClick={(e) => handleMyHobby("9")}
                  />
                  <input
                    type="button"
                    className={`hobby ${
                      myHobby.includes("10") ? "active" : ""
                    }`}
                    value={getHobby("10")}
                    onClick={(e) => handleMyHobby("10")}
                  />
                  <input
                    type="button"
                    className={`hobby ${
                      myHobby.includes("11") ? "active" : ""
                    }`}
                    value={getHobby("11")}
                    onClick={(e) => handleMyHobby("11")}
                  />
                  <input
                    type="button"
                    className={`hobby ${
                      myHobby.includes("12") ? "active" : ""
                    }`}
                    value={getHobby("12")}
                    onClick={(e) => handleMyHobby("12")}
                  />
                </div>

                <div className="faceLine">
                  <br />
                  <div className="section-title">
                    <b>תחביב נוסף :)</b>
                  </div>
                  <textarea
                    className="occupation"
                    rows="1"
                    value={hobbieFree}
                    maxLength={40}
                    onChange={(e) => setHobbieFree(e.target.value)}
                  ></textarea>
                </div>

                <div className="line" />
                <div className="section-title">
                  <b>מדדי אופי: היכן אני בסולם של</b>
                </div>
                {/* <div id='hright'>קלילות</div>  <div id='hleft'>רצינות</div> */}
                {rendercharacterButtons()}
                <div className="line" />
              </>
            )}
            {viewIndex === 3 && (
              <>
                {getHared() === 1 ? <AboutMeHared /> : ""} <br />
                <p style={{ margin: "0px", direction: "rtl" }}>
                  אנחנו ברבע הדרך! שנמשיך?
                </p>
                <div>
                  <video autoPlay loop muted className="cubesvideo">
                    <source src={cubesvideo} type="video/mp4" />
                  </video>
                </div>
              </>
            )}

            <div></div>
            {isErrorMessageDisplayed && (
              <div className="error-message">
                <h5>{errorMessage} </h5>
              </div>
            )}
            <br />
            {viewIndex !== 3 ? (
              <div
                className="button-text-center enlarge"
                id="btnSubmit"
                onClick={viewIndex === 1 ? onSubmitStep1 : onSubmitStep2}
              >
                שמירה והמשך
              </div>
            ) : (
              <div
                className="button-text-center"
                id="btnSubmit"
                onClick={() => {
                  // Cookies.get("newUser") == 1
                  //   ?
                  history.push("/aboutpartner");

                  alertUser("");
                }}
              >
                המשך
              </div>
            )}

            <Link to="/edit" style={{ textDecoration: "none" }}>
              <div className="button whiteBackNBorder" id="backBtn">
                {" "}
                חזרה
                <b>→</b>
              </div>
            </Link>
          </div>
        </>
      ) : (
        <DiceLoading />
      )}
    </form>
  );
};

export default AboutMe;
