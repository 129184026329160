import React from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import HeaderBack from "../../components/Header/HeaderBack";
import HowItWorkForm from "./howItWorkForm";

const HowItWork = () => {
  let history = useHistory();
  return (
    <div className="aboutdouble-full-screen">
      <HeaderBack />
      <form className="aboutdouble-form">
        <HowItWorkForm />

        <br />

        <Link to={"/"} style={{ textDecoration: "none" }}>
          <div className="button whiteBackNBorder" id="backBtn">
            <b>→</b>
            {"חזרה "}
          </div>
        </Link>
      </form>
    </div>
  );
};

export default HowItWork;
