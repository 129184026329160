const DiceLoading = () => {
  return (
    <div className="loading-container">
      <div className="scene">
        <div className="cube cube1">
          <div className="face front"></div>
          <div className="face back"></div>
          <div className="face left"></div>
          <div className="face right"></div>
          <div className="face top"></div>
          <div className="face bottom"></div>
        </div>
        <div className="cube cube2">
          <div className="face bottom"></div>
          <div className="face top"></div>
          <div className="face right"></div>
          <div className="face left"></div>
          <div className="face back"></div>
          <div className="face front"></div>
        </div>
      </div>
      <p>טוען את המידע...</p>
    </div>
  );
};

export default DiceLoading;
