import React from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import "./TellAboutUs.css";
import TellAboutUsHelper from "../../components/TellAboutUsHelper/TellAboutUsHelper";
import { getSignedIn } from "../../handlers/dictionaryHandler.js";
import HeaderBack from "../../components/Header/HeaderBack";

const TellAboutUs = () => {
  let history = useHistory();

  return (
    <div className="aboutdouble-full-screen">
      <HeaderBack />
      <br />
      <form className="almost-form" style={{ backgroundColor: "white" }}>
        <h1 className="h1-homepage">כותבים לדאבל הכרויות</h1>
        <TellAboutUsHelper />

        <div className="faceline">
          <div className="h-nomargin">
            {" "}
            רוצים גם לשתף אותנו? נשמח לשמוע! אפשר בווטסאפ
          </div>
          <a
            href={"https://wa.me/972549624084"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="whatsupp"></div>
          </a>
        </div>

        <Link
          to={getSignedIn() === "1" ? "/questionnaires" : "/"}
          style={{ textDecoration: "none" }}
        >
          <div className="button whiteBackNBorder" id="backBtn">
            {" "}
            <b>→</b>
            חזרה
          </div>
        </Link>
      </form>{" "}
    </div>
  );
};

export default TellAboutUs;
