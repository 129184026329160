import React, { useRef, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import "./ForumMain.css";
import axios from "axios";
import Header from "../../components/Header/Header";
import Popup from "reactjs-popup";
import ForumRules from "../../components/ForumRules/ForumRules";
import useScrollPosition from "use-scroll-position";
import { Button } from "rsuite";
import Linkify from "react-linkify";
import { getRoom, setRoom } from "../../handlers/chatRoomsHandler";
import Footer from "../../components/Footer/Footer";
import DiceLoading from "../../components/DiceLoading";

const ForumMain = () => {
  const [isDataLoaded, setIsDataLoaded] = useState(true);
  const [cntMsg, setCntMsg] = useState(30);
  const [newMsgLength, setNewMsgLength] = useState(0);
  const [isForumRulesDisplayed, setIsForumRulesDisplayed] = useState(false);
  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [newMsg, setNewMsg] = useState("");
  const [msgsForum, setMsgsForum] = useState([]);
  const [section, setSection] = useState(0);
  const msgContainerRef = useRef();
  const [tempArrayDates, setTempArrayDates] = useState([]);
  const [isNearTheEnd, setIsNearTheEnd] = useState(false);
  const [isChangeNicknamePopupDisplayed, setIsChangeNicknamePopupDisplayed] =
    useState(false);

  const [isChangeNicknameErrorDisplayed, setIsChangeNicknameErrorDisplayed] =
    useState(false);
  const [newNickname, setNewNickname] = useState("");
  const [errorMessageInResetPassword, setErrorMessageInResetPassword] =
    useState("");
  const [isNicknameChangedSuccessfully, setIsNicknameChangedSuccessfully] =
    useState(null);

  const alertUser = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessage(message);
  };

  const handleMsg = (msg) => {
    setNewMsgLength(msg.length);
    setNewMsg(msg);
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  const loadMsgsForum = async (section) => {
    try {
      // const room = getRoom();
      const room = localStorage.getItem("room");
      // console.log("roommbefore " + room);
      // console.log("Room: " + room);
      // console.log("!!!!");
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/pForumGetMsg",
        {
          room: room,
          section: section,
        },
        config
      );
      // console.log(response);
      const userData = response.data;
      const tempArray = [...msgsForum];

      // console.log(userData);
      userData.forEach((currentMsg) => {
        const tempArrayIds = tempArray.map((current) => current.idMsg);
        if (!tempArrayIds.includes(currentMsg.idMsg)) {
          tempArray.push(currentMsg);
        }
      });
      setMsgsForum(tempArray.sort((a, b) => a.idMsg - b.idMsg));
    } catch (error) {
      // probebly user is nost authenticated or server is down
    }
  };

  useEffect(() => {
    console.log("Loading Data");
    loadMsgsForum(section);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log("Loading New Messages");
      loadMsgsForum(0);
    }, 1000 * 240);

    return () => {
      clearInterval(interval);
    };
  }, [msgsForum]);

  useEffect(() => {
    // msgContainerRef.scrollIntoView({ behavior: "smooth" });
  }, [msgsForum]);

  useEffect(() => {
    const onScroll = (e) => {
      // console.log(e.target.documentElement.scrollTop);
      // setScrolling(e.target.documentElement.scrollTop > scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const changeNicknameClicked = async () => {
    if (newNickname !== "דאבל") {
      if (newNickname.length > 1 && newNickname != "") {
        try {
          const response = await axios.post(
            process.env.REACT_APP_LOCALHOST_ADDRESS + "/pChangeNickname",
            { nick: newNickname },
            config
          );

          if (response.status === 200) {
            setIsChangeNicknameErrorDisplayed(false);
            setIsNicknameChangedSuccessfully(true);
            setInterval(() => {
              setIsChangeNicknamePopupDisplayed(false);
              setIsNicknameChangedSuccessfully(null);
            }, 5000);
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            setIsChangeNicknameErrorDisplayed(true);
            setErrorMessageInResetPassword(
              "ארעה תקלה זמנית, יש לנסות שוב בהמשך"
            );
          }
        }
      } else {
        setIsChangeNicknameErrorDisplayed(true);
        setErrorMessageInResetPassword("שם קצר מידי");
      }
    } else {
      setIsChangeNicknameErrorDisplayed(true);
      setErrorMessageInResetPassword("שם שמור, יש לבחור שם אחר");
    }
  };

  const onSubmit = async () => {
    if (newMsgLength > 0) {
      // console.log("All is valid");

      // console.log(newMsg);

      try {
        const response = await axios.post(
          process.env.REACT_APP_LOCALHOST_ADDRESS + "/PNewMsgForum",
          {
            room: `${getRoom()}`,
            newMsg: newMsg,
            msgRefer: "0",
          },
          config
        );
        if (response.data === true) {
          // history.push("/questionnaires");
          handleMsg("");
          document.getElementById("newMsg").value = "";
          loadMsgsForum(0);
        } else {
          alertUser("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
        }
      } catch (error) {
        alertUser("יש שגיאה בשרת או שאבד החיבור לאינטרנט, אנא נסה שנית");
      }
    } else {
      alertUser("לא כתבת הודעה");
    }
  };

  return (
    <form className="forum-main-form">
      <Header />
      {isDataLoaded === true ? (
        <>
          <br></br>
          <div id="h1"></div>

          <div className="form-body">
            <div className="messegeMatch">
              <div
                className="infoicon-empty"
                onClick={() => setIsForumRulesDisplayed(true)}
              />
              <div
                className="nickicon"
                onClick={() => setIsChangeNicknamePopupDisplayed(true)}
              />

              <div
                className={
                  localStorage.getItem("room") == 4 ? "zoomdatelogo" : ""
                }
              />
              <a
                href="http://zoomdate.net/index.html"
                target="_blank"
                rel="noopener noreferrer"
                style={{ float: "left", marginLeft: "12px" }}
              >
                {localStorage.getItem("room") == 4 ? "לאתר של פנינה" : ""}
              </a>
            </div>
            <Popup
              open={isForumRulesDisplayed}
              contentStyle={{
                minWidth: "30%",
                maxWidth: "60%",
                borderRadius: "5px",
              }}
              position="right center"
              onClose={() => setIsForumRulesDisplayed(false)}
              closeOnDocumentClick
              closeOnEscape
            >
              {ForumRules}
            </Popup>
            <Popup
              open={isChangeNicknamePopupDisplayed}
              contentStyle={{
                minWidth: "30%",
                maxWidth: "60%",
                borderRadius: "5px",
              }}
              position="right center"
              onClose={() => setIsChangeNicknamePopupDisplayed(false)}
              closeOnDocumentClick
              closeOnEscape
            >
              <div className="change-nickname-container">
                {isNicknameChangedSuccessfully == true ? (
                  <h4>החלפת את הכינוי :)</h4>
                ) : (
                  <>
                    <div className="change-nickname-title">
                      אני רוצה כינוי חדש בפורום
                    </div>

                    <input
                      className="change-nickname-email-input"
                      type="name"
                      id="name"
                      maxlength={7}
                      value={newNickname}
                      autocomplete="off"
                      placeholder="כינוי"
                      onChange={(e) => {
                        setIsChangeNicknameErrorDisplayed(false);
                        setNewNickname(e.target.value);
                      }}
                    ></input>
                    <div
                      className="change-nickname-popup-button"
                      onClick={changeNicknameClicked}
                    >
                      אישור
                    </div>
                    {isChangeNicknameErrorDisplayed ? (
                      <h4>{errorMessageInResetPassword}</h4>
                    ) : null}
                  </>
                )}
              </div>
            </Popup>
            <div className="allMsg">
              <div>
                <div className="faceline">
                  <div
                    className="uploadicon"
                    onClick={() => {
                      loadMsgsForum(section + 1);
                      setSection(section + 1);
                    }}
                  >
                    {/* {" "}
                    הודעות קודמות{" "} */}
                  </div>
                </div>

                {msgsForum.map((currentMsg, index) => {
                  // if (!tempArrayDates.includes(currentMsg.msgDate)) {
                  //   setTempArrayDates(currentMsg.msgDate);
                  // }
                  let isDateTitleNeeded = false;

                  if (index != 0) {
                    if (msgsForum[index - 1].msgDate !== currentMsg.msgDate) {
                      isDateTitleNeeded = true;
                    }
                  } else {
                    isDateTitleNeeded = true;
                  }

                  return (
                    <div
                      onLoadedData={() => {
                        setTempArrayDates(currentMsg.msgDate);
                      }}
                    >
                      <div className="msg-date">
                        {isDateTitleNeeded
                          ? currentMsg.msgDate
                          : // setTempArrayDates(currentMsg.msgDate)
                            ""}
                      </div>
                      <div
                        key={currentMsg.idMsg}
                        className={
                          currentMsg.myMsg === 1
                            ? "forum-line-my-msg"
                            : "forum-line"
                        }
                      >
                        <div className="nickname">
                          <div>{currentMsg.nick}</div>
                          <div>{currentMsg.msgTime}</div>
                        </div>
                        <div className="forumMsg">
                          <Linkify
                            properties={{
                              target: "_blank",
                              style: { color: "blue" },
                            }}
                          >
                            {currentMsg.msg}
                          </Linkify>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="newMsgArea">
              <div className="faceline">
                <textarea
                  className="newMsg"
                  maxLength="1000"
                  id="newMsg"
                  onChange={(e) => handleMsg(e.target.value)}
                ></textarea>

                <div
                  className="sendicon"
                  onClick={newMsgLength > 0 ? onSubmit : ""}
                />
              </div>
              <div className="line" />
              {isErrorMessageDisplayed && (
                <div className="error-message">
                  <h5>{errorMessage} </h5>
                </div>
              )}
            </div>
            <div id="htext">{newMsgLength + "/1000 "} </div>
            <Link to="/forumrooms" style={{ textDecoration: "none" }}>
              <div className="button" id="backBtn">
                {" "}
                חזרה
              </div>
            </Link>
            <div className="lineDice" />
          </div>
          <Footer />
        </>
      ) : (
        <DiceLoading />
      )}
    </form>
  );
};

export default ForumMain;
