import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../App.css";
import "./Subscribe.css";
import axios from "axios";
import {
  getSignedIn,
  getVip,
  getGender,
  getFirstName,
  getVipKind,
  getWordByGender,
} from "../../handlers/dictionaryHandler.js";
import HeaderBack from "../../components/Header/HeaderBack";
import Footer from "./../../components/Footer/Footer";
import Cookies from "js-cookie";
import DiceLoading from "../../components/DiceLoading.js";

const Subscribe = () => {
  let history = useHistory();
  const [fullName, setFullName] = useState("");
  const [cellular, setCellular] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [payRoute, setPayRoute] = useState("");
  const [tz, setTz] = useState("");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage1, setErrorMessage1] = useState("");
  const [urlLink, setUrlLink] = useState("");
  const [starsAreaVisible, setStarsAreaVisible] = useState(false);
  const [prevVip, setPrevVip] = useState(false);
  const [cntStars, setCntStars] = useState(0);
  const [prevTarVip, setPrevTarVip] = useState("");
  const [prevKindOfVip, setPrevKindOfVip] = useState("");
  const [prevMonthVip, setPrevMonthVip] = useState("");
  const [submitOlreadyClick, setSubmitOlreadyClick] = useState(false);
  const [isGreenReady, setIsGreenReady] = useState(true);

  const handlePayRoute = (route) => {
    setPayRoute(route);
    const newAmount =
      route === "6"
        ? "80"
        : route === "13"
        ? "100"
        : route === "150"
        ? "180"
        : "50";
    setAmount(newAmount);
    setDescription(
      route === "6"
        ? "מנוי חצי שנתי"
        : route === "13"
        ? "מבצע מצטרפים חדשים"
        : route === "150"
        ? "מנוי קבוע "
        : "מנוי לחודשיים"
    );
    if (submitOlreadyClick === true) {
      onSubmit(newAmount);
    }

    // setAmount(route === "6" ? "65" : "100");
    // setDescription(route === "6" ? "מנוי חצי שנתי" : "מנוי שנתי");
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  useEffect(async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/PsubscribeGet",
        config
      );
      const userData = response.data;
      if (userData.fullName !== "") {
        setFullName(userData.fullName);
        setCellular(userData.cellular);
        setEmail(userData.email);
        setCity(userData.city);
        setCntStars(userData.cntStars);
        setPrevTarVip(userData.payDate);
        setPrevKindOfVip(userData.gift);
        setPrevMonthVip(userData.payRoute);
      } else {
        // in case its a new user
      }
    } catch (error) {
      // probebly user is not authenticated or server is down
    }
  }, []);

  const alertUser = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessage(message);
  };

  const alertUser1 = (message) => {
    setIsErrorMessageDisplayed(true);
    setErrorMessage1(message);
  };

  const onSubmitStars = async () => {
    if (cntStars >= 10) {
      const response = await axios.post(
        process.env.REACT_APP_LOCALHOST_ADDRESS + "/buyWithStars",
        { kindUse: "1", amountUse: "20" },
        config
      );
      if (response.data !== false) {
        alertUser1("רכשת מנוי בהצלחה :)");
      }
    } else {
      alertUser1(" אין ברשותך מספיק נקודות. אפשר לאסוף נקודות ולנסות שוב");
    }
  };

  const onSubmit = async (newAmount) => {
    setIsGreenReady(false);
    if (cellular.trim() && cellular.trim().length > 9) {
      if (email.trim()) {
        if (tz.trim().length > 6) {
          if (payRoute !== "") {
            const response = await axios.post(
              process.env.REACT_APP_LOCALHOST_ADDRESS + "/paymentDetails",
              {
                amount: newAmount,
                name: fullName,
                emails: email,
                city: city,
                phone: cellular,
                tz: tz,
                payRoute: payRoute,
                description: description,
              },
              config
            );

            if (response.data !== false) {
              alertUser("התשלום מאובטח, ופרטי התשלום אינם נשמרים");
              setSubmitOlreadyClick(true);
              setUrlLink(response.data.url);
              setIsGreenReady(true);

              // history.push("/questionnaires");
              // setIsErrorMessageDisplayed(false);
            }
          } else {
            alertUser("נא לבחור מסלול מנוי");
            console.log("payRoute is not valid");
          }
        } else {
          alertUser(" תעודת זהות לא תקינה");
          console.log("User is already exists");
        }
      } else {
        alertUser("כתובת האימייל לא תקינה");
        console.log("User is already exists");
      }
    } else {
      alertUser("מספר הטלפון לא תקין, המספר חייב להיות ישראלי");
      console.log("Phone number is not valid");
    }
  };

  return (
    <div className="aboutdouble-full-screen">
      <HeaderBack />
      <br />
      <form className="subscribe-form">
        <div className="helloline">
          <div
            className={getGender() === "1" ? "aboutmeiconw" : "aboutmeiconm"}
          ></div>
          <div className="lbl_hello"> שלום {getFirstName()}</div>
        </div>
        <h1 className="h-nomargin">רכישת מנוי לאתר דאבל הכרויות</h1>
        <h1>{getVip() === "0" ? "" : "אתם כבר מנויים :)"} </h1>
        {/* <div className="tellAboutUsSection right">
          {" "}
          <b> מה מקבלים ברכישת מנוי לאתר דאבל הכרויות?</b>
          <br />
          1. יש מערכת שכל שבוע חושבת עליך ומחפשת לך התאמה אישית! לא כל שבוע היא
          מוצאת אבל כל שבוע היא מחפשת התאמה אישית בשבילך.
          <b> אתר שעובד בשבילך</b>.
          <br />
          2. על מנת לראות התאמה של דאבל, צריך שלפחות
          <b> אחד משני הצדדים </b>
          יהיה מנוי לאתר.
          <br />
          3. בנוסף, ברכישת מנוי אתם
          <b> שותפים </b>
          לכיסוי הוצאות של אתר דאבל הכרויות ונותנים לאתר כוח להמשיך לפעול.
          <br />
          4. <b>פיצ'רים </b>
          נוספים באתר פתוחים רק לחברים מנויים באתר, כמו פעילויות לדייטים.
          <br />
          <br />
          <b> מדוע אנו גובים דמי מנוי? </b>
          <br />
          תחזוקת האתר - מענה, אחזקה שוטפת, המשך פיתוח, שיווק, אחסון ועוד - כל
          אלה ועוד הם דברים שעולים ממון, והרבה.
          <br />
          אנחנו לא חושבים שצריך לעבוד בקונספט של תרומות - כי אנחנו לא חושבים
          שאנחנו ציבור שזקוק לתרומות אלא אנחנו ציבור חזק.
          <br />
          שאלה: אז למה שלא תכסו עלויות בעזרת פרסומות?
          <br />
          תשובה: נהדר! רוצה לפרסם אצלנו או מכיר מישהו שרוצה? נשמח שתקשרו בינינו.
          <br />
          <br />* האתר הוקם מחוויה אישית שלי ושל חברי, במטרה לעשות טוב ולהקל על
          תקופת החיפוש. אנחנו קשובים ומשתדלים כל הזמן לשפר ולהיות כמה שיותר
          רגישים ומדויקים.
          <br />
          <br />* שימו לב, דאבל לא מתחייב למצוא התאמה או התאמה הדדית בתקופת
          המנוי.
        </div> */}

        <div className="vipDescription" style={{ fontSize: "larger" }}>
          מתחילים להכיר! איזה מסלול מתאים לכם?
        </div>
        <div className="vipDescription">
          ✅ תשלום
          <b> חד פעמי</b> במחיר שפוי.
        </div>
        <div className="h-nomargin">
          {/* <b>רבע מההכנסות מוקדשות לתושבי הדרום/הלחימה.</b> */}
        </div>
        <br />
        <div className={"subscribe-inputs"}>
          {Cookies.get("newUser") == 1 ? (
            <div
              className={`payRoutebtn newUser ${
                payRoute === "13" ? "active" : ""
              }`}
              key="13"
              onClick={(e) => handlePayRoute("13")}
            >
              מבצע להיום בלבד! מנוי שנתי ב - 100 ש"ח. (ומקבלים חודש נוסף מתנה,
              כמו כולם)
              <div className="star animate" />
            </div>
          ) : (
            <></>
          )}

          {/* <div className="parent-payRoute"> */}

          <div
            className={`payRoutebtn ${payRoute === "2" ? "active" : ""}`}
            key="2"
            onClick={(e) => handlePayRoute("2")}
          >
            2 חודשים ב - 50 ש"ח
            <div style={{ fontStyle: "italic" }}>התחלה מושלמת להכיר!</div>
            {/* (*) תוכנית מלאה - שנה + נומרולגית - 250 ש"ח */}
            <div className="flower1" />
          </div>
          <div
            className={`payRoutebtn ${payRoute === "6" ? "active" : ""}`}
            key="6"
            onClick={(e) => handlePayRoute("6")}
          >
            6 חודשים ב- 80 ש"ח
            <div style={{ fontStyle: "italic" }}>
              יותר התאמות, יותר הזדמנויות!
            </div>
            <div className="flower2" />
          </div>

          <div
            className={`payRoutebtn ${payRoute === "150" ? "active" : ""}`}
            key="150"
            onClick={(e) => handlePayRoute("150")}
          >
            מנוי קבוע ב - 180 ש"ח
            <div style={{ fontStyle: "italic" }}>בלי דאגות, גישה מלאה!</div>
            {/* (*) תוכנית מלאה - שנה + נומרולגית - 250 ש"ח */}
            <div className="flower3" />
          </div>
          {/* </div> */}

          <br />
          {/* <div className="tellAboutUsSection right">
            (*) בתוכנית המלאה - במשך שנה תוכלו לצפות בהתאמות שדאבל מוצא לכם.
            ובנוסף - מקבלים
            <b> יעוץ נומרולוגי ! </b>
            נומרולוגית תיצור איתך קשר ב - 3 הימים הקרובים לתיאום פגישת יעוץ
            והכוונה. אורך היעוץ יהיה כ- 30 דקות.
          </div> */}
          <div
            className="reset-password-button"
            onClick={() => setStarsAreaVisible(!starsAreaVisible)}
          >
            {" "}
            רכישה עם נקודות ←
          </div>
          <div className={starsAreaVisible ? "shadowcard" : "invisible"}>
            <div className="parent-payRoute">
              <div
                className={`payRoutebtn ${payRoute === "1" ? "active" : ""}`}
                // key="6"
                onClick={(e) => setPayRoute("1")}
              >
                1 חודש - 20 נקודות (בנקודות בלבד)
                <div className="star" />
              </div>
            </div>
            <div className="lbl">(צברת {cntStars} נקודות )</div>

            <div className="lbl">
              אוספים נקודות <Link to="/collectstars">{" כאן"}</Link>
            </div>

            {getVip() === "0" ? (
              <div
                className="button-text-center"
                style={{ width: "47.5%" }}
                onClick={onSubmitStars}
              >
                רכישה עם נקודות
              </div>
            ) : (
              "אתם כבר מנויים"
            )}
            {isErrorMessageDisplayed && (
              <div className="error-message">
                <h5>{errorMessage1} </h5>
              </div>
            )}
          </div>

          <div
            className="reset-password-button"
            onClick={() => setPrevVip(!prevVip)}
          >
            {" "}
            מנוי אחרון ←
          </div>
          <div className={prevVip ? "shadowcard" : "invisible"}>
            <div className="lbl">
              {" בתאריך " +
                prevTarVip +
                " - " +
                getVipKind(prevKindOfVip) +
                " מנוי של " +
                prevMonthVip +
                " חודשים."}
              <br />
              {getWordByGender("enjoy_and_find_love") + " :)"}
            </div>
          </div>
          <div className="tellAboutUsSection right" />

          <div className="h-nomargin">
            וודאו שהפרטים נכונים, והשלימו ת.ז. (לא נשמר במערכת, זה לצורך התשלום
            בלבד) במידה ויש טעות בטלפון או באימייל - צרו איתנו קשר בווטסאפ לפני
            רכישת המנוי!
          </div>
          <div className="like-input"> {fullName}</div>
          <div className="like-input">{cellular}</div>
          <div className="like-input">{email}</div>
          <div className="like-input">{city}</div>
          <input
            type="number"
            value={tz}
            autocopmlete="off"
            placeholder="תעודת זהות"
            onChange={(e) => setTz(e.target.value)}
          ></input>

          <br />
          {payRoute === "13"
            ? "המנוי שנבחר הוא: מבצע לשנה למצטרפים חדשים "
            : " "}
          {payRoute === "6" ? "המנוי שנבחר הוא: 6 חודשים " : " "}
          {payRoute === "2" ? "המנוי שנבחר הוא: מנוי לחודשיים  " : " "}
          {payRoute === "150" ? "המנוי שנבחר הוא: מנוי קבוע  " : " "}
          {getVip() === "0" ||
          (Cookies.get("newUser") == 1 && payRoute === "13") ? (
            <div
              className="button-text-center"
              id="btnSubmit"
              onClick={() => onSubmit(amount)}
            >
              הפרטים נכונים, אפשר להמשיך
            </div>
          ) : (
            "אתם כבר מנויים"
          )}
          {isErrorMessageDisplayed && (
            <div className="error-message">
              <h5>{errorMessage} </h5>
            </div>
          )}
          {isGreenReady === true ? (
            <>
              {urlLink !== "" ? (
                <iframe
                  src={urlLink}
                  className="ifarme"
                  title="רכישה מאובטחת"
                ></iframe>
              ) : (
                ""
              )}
            </>
          ) : (
            <div className="loading-data-container">
              <div className="loading-data-div">
                <DiceLoading />
                <h4>מתחבר לחשבונית ירוקה לתשלום מאובטח</h4>
              </div>
            </div>
          )}
        </div>
        <div className="whatsupp-subscribe">
          <br />

          <div className="faceline">
            <div className="h-nomargin"> לכל שאלה אפשר בווטסאפ</div>
            <a
              href={"https://wa.me/972549624084"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="whatsupp"></div>
            </a>
          </div>
          <div className="h-nomargin-small">
            * שימו לב, דאבל לא מתחייב למצוא התאמה או התאמה הדדית בתקופת המנוי.
            אם הגעת לעמוד הזה - כנראה שעכשיו יש לך התאמה שדאבל מצא לך.
            <br />* המנוי הוא מכאן ולהבא ולא עבור התאמות קודמות, הרי ההתאמה
            הקודמת כבר לא מופיעה אצל הצד השני.
          </div>
        </div>
        <Link
          to={getSignedIn() === "1" ? "/questionnaires" : "/"}
          style={{ textDecoration: "none" }}
        >
          <div className="button whiteBackNBorder" id="backBtn">
            {" "}
            <b>→</b>
            חזרה
          </div>
        </Link>
      </form>{" "}
      {/* <iframe src={"https://google.com"} /> */}
      <Footer />
    </div>
  );
};

export default Subscribe;
