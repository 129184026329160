import "./App.css";
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import axios from "axios";
import ReactPixel from "react-facebook-pixel";

import Signup from "./pages/SignUp/SignUp";
import Questionnaires from "./pages/Questionnaires/Questionnaires";
import Miror from "./pages/Miror/Miror";
import LogIn from "./pages/Login/Login";
import Homepage from "./pages/Homepage/Homepage";
import DoubleMatch from "./pages/DoubleMatch/DoubleMatch";
import AboutPartner from "./pages/AboutPartner/AboutPartner";
import AboutMe from "./pages/AboutMe/AboutMe";
import ForumMain from "./pages/ForumMain/ForumMain";

import ForumRooms from "./pages/ForumRooms/ForumRooms";
import "../src/handlers/setUpAxios";
import AboutDouble from "./pages/AboutDouble/AboutDouble";
import ContactUs from "./pages/ContactUs/ContactUs";
import TellAboutUs from "./pages/TellAboutUs/TellAboutUs";
import Collaboration from "./pages/Collaboration/Collaboration";
import Article from "./pages/Article/Article";
import SuperMatchMaker from "./pages/SuperMatchMaker/SuperMatchMaker";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import Subscribe from "./pages/Subscribe/Subscribe";
import Terms from "./pages/Terms/Terms";
import NotFound from "./pages/NotFound/NotFound";
import MyProfile from "./pages/MyProfile/MyProfile";
import SuccessPayment from "./pages/SuccessPayment/SuccessPayment";
import FailurePayment from "./pages/FailurePayment/FailurePayment";
import FunDates from "./pages/FunDates/FunDates";
import Almost from "./pages/Almost/Almost";
import AddPlace from "./pages/AddPlace/AddPlace";
import NotificationManager from "./utils/NotifcationManager";
import EditDetails from "./pages/EditDetails/EditDetails";
import FindPlace from "./pages/FindPlace/FindPlace";
import QAForm from "./pages/QAForm/QAForm";
import DoubleFriend from "./pages/DoubleFriend/DoubleFriend";
import BlogRomy from "./pages/BlogRomy/BlogRomy";
import IntoSite from "./components/HeaderHomepage/IntoSite";
import HowItWork from "./components/HeaderHomepage/howItWork";
import PrevMatches from "./components/PrevMatches/PrevMatches";
import CollectStars from "./pages/AddPlace/CollectStars";
import Pic from "./pages/Pic/Pic";

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
// const advancedMatching = { em: 'some@email.com' };
ReactPixel.init("2092482217573520", null, options);

ReactPixel.pageView(); // For tracking page view
// ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
// ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
// ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
// ReactPixel.trackSingleCustom('PixelId', event, data);

const App = () => {
  return (
    <div className="App">
      <div className="double">
        <Router>
          <Switch>
            <Route path="/signup" exact component={Signup} />
            <Route path="/" exact component={Homepage} />
            <Route path="/login" exact component={LogIn} />
            <Route path="/questionnaires" exact component={Questionnaires} />
            <Route path="/aboutme" exact component={AboutMe} />
            <Route path="/aboutpartner" exact component={AboutPartner} />
            <Route path="/miror" exact component={Miror} />
            <Route path="/pics" exact component={Pic} />
            <Route path="/edit" exact component={EditDetails} />
            <Route path="/doublematch" component={DoubleMatch} />
            <Route path="/forumrooms" exact component={ForumRooms} />
            <Route path="/forummain" exact component={ForumMain} />
            <Route path="/aboutdouble" exact component={AboutDouble} />
            <Route path="/contactus" exact component={ContactUs} />
            <Route path="/doublefriend" exact component={DoubleFriend} />
            <Route path="/tellaboutus" exact component={TellAboutUs} />
            <Route path="/terms" exact component={Terms} />
            <Route path="/intosite" exact component={IntoSite} />
            <Route path="/howitwork" exact component={HowItWork} />
            <Route path="/prevmatches" exact component={PrevMatches} />
            <Route path="/qa" exact component={QAForm} />
            <Route path="/collaboration" exact component={Collaboration} />
            <Route path="/article" exact component={Article} />
            <Route path="/blog" exact component={BlogRomy} />
            <Route path="/supermatchmaker" exact component={SuperMatchMaker} />
            <Route path="/myprofile" exact component={MyProfile} />
            <Route path="/changepassword" exact component={ChangePassword} />
            <Route path="/subscribe" exact component={Subscribe} />
            <Route path="/successpayment" exact component={SuccessPayment} />
            <Route path="/failurepayment" exact component={FailurePayment} />
            <Route path="/fundates" exact component={FunDates} />
            <Route path="/almost" exact component={Almost} />
            <Route path="/addplace" exact component={AddPlace} />
            <Route path="/collectstars" exact component={CollectStars} />
            <Route path="/findplace" exact component={FindPlace} />
            {/* <Route path="*" exact component={NotFound} /> */}
            <Route path="/404" exact component={NotFound} />
            <Redirect to="/404" />
            {/* <Route component={NotFound} /> */}
            {/* <Route component={() => <div>404 Not found </div>} /> */}
          </Switch>
        </Router>
      </div>
    </div>
  );
};

export default App;
